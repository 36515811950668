import React, { useEffect, useState } from "react";

const useRowPerPage = (
  dataGridRef,
  tableContentHeight,
  tableRowHeight,
  rowData
) => {
  const [rowsPerPage, setRowsPerPage] = useState(0);
  useEffect(() => {
    if (rowData?.length) {
    //   setTimeout(() => {
        let tableBodyContentElement = document.querySelector(
          ".MuiDataGrid-virtualScroller"
        );
        console.log(tableBodyContentElement, "tableBodyContentElement");
        if (tableBodyContentElement) {
          const totalRows = Math.floor(
            tableBodyContentElement?.clientHeight / tableRowHeight
          );
          setRowsPerPage(totalRows);
        }
    //   }, 1000);
    }
  }, [tableContentHeight, rowData]);
  return [rowsPerPage];
};

export default useRowPerPage;
