import React, { useState } from "react";
import Index from "../Index";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import { manageAutoSquareTime, manageDisplaySettings, manageUserSetting } from "../../redux/user/action";

const AutoSquareTime = (props) => {
  const { setAutoSquareModal } = props;
  const dispatch = Index.useDispatch();
  const { token, displaySettings, autoSquareTime } = Index.useSelector((store) => store.UserReducer);
  const currentDate = dayjs().format("YYYY-MM-DD");

  const initialRows = {
    NSE: {
      title: "Equity",
      name: "NSE",
      time: currentDate + "T15:30",
      cronTime: dayjs(currentDate + "T15:30").format("mm HH * * *"),
    },
    NFO: {
      title: "F&O",
      name: "NFO",
      time: currentDate + "T15:30",
      cronTime: dayjs(currentDate + "T15:30").format("mm HH * * *"),
    },
    MCX: {
      title: "Commodity",
      name: "MCX",
      time: currentDate + "T23:30",
      cronTime: dayjs(currentDate + "T23:30").format("mm HH * * *"),
    },
  };

  const [rows, setRows] = useState(autoSquareTime);


  const handleTimeChange = (newValue, key) => {
    const timeValue = newValue ? newValue.format("YYYY-MM-DDTHH:mm:ss") : "";
    const cronTime = timeValue
      ? dayjs(timeValue).format("mm HH * * *")
      : timeValue;
    setRows((prevRows) => ({
      ...prevRows,
      [key]: {
        ...prevRows[key],
        time: timeValue,
        cronTime,
      },
    }));
  };

  const handleSaveTime = () => {
    dispatch(manageUserSetting(token, "autoSquareTime", rows));
    dispatch(manageUserSetting(token, `displaySetting.AutoSquare`, "on"));

    dispatch(
      manageDisplaySettings({ ...displaySettings, ["AutoSquare"]: "on" })
    );

    dispatch(manageAutoSquareTime(rows));

    setAutoSquareModal(false);
  };

  return (
    <>
      <Index.Box className="auto-square-wrapper">
        <Index.Box className="auto-square-content">
          <Index.Box className="auto-square-flex">
            {Object?.entries(rows)?.map(([key, item]) => (
              <Index.Box className="" key={item.name}>
                <Index.Box className="auto-square-category">
                  <Index.Typography className="auto-square-category-text" >{item.title}</Index.Typography>
                </Index.Box>
                <Index.Box className="auto-square-time-main">
                  <Index.Box className="datepicker-wrap time-picker-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        value={dayjs(item.time)}
                        onChange={(newValue) => handleTimeChange(newValue, key)}
                        className="time-picker"
                      />
                    </LocalizationProvider>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            ))}
          </Index.Box>
          <Index.Box className="auto-square-btn-main">
            <Index.PrimaryButton
              className="primary-btn index-btn okay-btn auto-square-btn"
              btnLabel="Save"
              disableRipple={false}
              onClick={handleSaveTime}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AutoSquareTime;
