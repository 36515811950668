import Svg from "../assets/svg";
import Png from "../assets/png";
import Jpg from "../assets/jpg";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Modal,
  ListItem,
  Tab,
  Tabs,
  Pagination,
  OutlinedInput,
  Autocomplete,
  InputAdornment,
  IconButton,
  Switch,
  Stack,
  Select,
  Menu,
  MenuItem,
  Checkbox,
  Skeleton,
} from "@mui/material";
// import PrimaryButton from "../component/primarybutton/PrimaryButton";
import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import Header from "../component/DefaultLayout/Header";
import SideBar from "../component/DefaultLayout/SideBar";
import ChartHeader from "../component/user/chartheader/ChartHeader";
import RecentIndex from "../component/modalbody/RecentIndex";
import { DataGrid } from "@mui/x-data-grid";
import CustomLineChart from "../component/custom-chart/customlinechart/CustomLineChart";
import DashedChart from "../component/custom-chart/dashedChart/DashedChart";
import CustomAreaChart from "../component/custom-chart/area-chart/CustomAreaChart";
import CustomCandlestickChart from "../component/custom-chart/cendalstick-chart/CustomCandlestickChart";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import OrderChartHeader from "../component/user/chartheader/OrderChartHeader";
import Footer from "../component/DefaultLayout/Footer";
import ReportModal from "../component/modalbody/ReportModal";
import SideBarTop from "../component/common/SideBarTop";
import Overlay from "../component/common/Overlay";
import NoRowsOverlay from "../component/common/NoRowsOverlay";

import RedButton from "../component/Button/redbutton/RedButton";
import PrimaryButton from "../component/Button/primarybutton/PrimaryButton";
import RoundButton from "../component/Button/roundbutton/RoundButton";
import { DataService } from "../config/DataService";
import { Api } from "../config/Api";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { DateRangePicker } from "react-date-range";
import EditTargetLoss from "../component/modalbody/EditTargetLoss";
import { GridOverlay } from '@mui/x-data-grid';
import MenuIcon from '@mui/icons-material/Menu';
import TableLoader from "../component/loader/TableLoader";
import Tooltip from "@mui/material/Tooltip";


const Index = {
  moment,
  useSelector,
  useDispatch,
  useLocation,
  FormControl,
  useNavigate,
  SearchIcon,
  DeleteOutlineIcon,
  Switch,
  VisibilityOff,
  IconButton,
  Visibility,
  Navigate,
  Menu,
  MenuItem,
  OutlinedInput,
  EditIcon,
  toast,
  ToastContainer,
  Api,
  DataService,
  Autocomplete,
  Select,
  Svg,
  Png,
  Jpg,
  Box,
  List,
  Button,
  Typography,
  InputAdornment,
  TextField,
  FormHelperText,
  PrimaryButton,
  Link,
  Overlay,
  Header,
  SideBar,
  ChartHeader,
  RedButton,
  RecentIndex,
  ListItem,
  Modal,
  DataGrid,
  CustomLineChart,
  DashedChart,
  CustomAreaChart,
  CustomCandlestickChart,
  Grid,
  OrderChartHeader,
  Footer,
  RoundButton,
  Tab,
  Tabs,
  ReportModal,
  SideBarTop,
  Overlay,
  Pagination,
  Stack,
  Checkbox,
  DateRangePicker,
  EditTargetLoss,
  GridOverlay,
  MenuIcon,
  TableLoader,
  Skeleton,
  Tooltip,
  NoRowsOverlay,
};

export default Index;
