import Index from "../Index";

const NoRowsOverlay = ({ loader=false, message= "No record found" }) => {
  return (
    <Index.Box className="table-no-record-main">
      {loader ? (
        <Index.TableLoader />
      ) : (
        <Index.Typography className="table-no-record-text">
          {message}
        </Index.Typography>
      )}
    </Index.Box>
  );
};

export default NoRowsOverlay;
