import React, { useEffect, useRef, useState } from "react";
import Index from "../../Index";
import { toast } from "react-toastify";
// import Modal from "@mui/material/Modal";
import * as htmlToImage from "html-to-image";
import Modal from "../../modal/Modal";
import useKeyboardShortcut from "../../common/keyboard-shortcuts/useKeyboardShortcut";
import { manageDrawingButton, manageDrawingToolActiveDeactive, manageModalParameter } from "../../../redux/user/action";
import { useDispatch } from "react-redux";
import Constants from "../../common/constants";
import DrawChartButton from "../../modal/DrawChartButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const OrderChartHeader = ({
  token,
  // currentPrice,
  headerData,
  stockTag,
  // closePrice,
  entryPrice,
  defaultTag,
  setOpenModel,
  openModel,
  deleteLine,
  handleClose,
  updateOrder,
  setUpdateOrder,
  isStopLossHit,
  exchange_timestamp,
  placeOrderData,
  setScriptData,
  scriptData,
  editOrderData,
  setEditOrderData,
  orderCurrentChart,
  setAllTagsData,
  removeStockTag,
  activeChart,
  allTagsData,
  socket,
  live,
  stockData,
  livePrice,
  handleUpdateStock,
  isDrawingChart,
  drawingChart,
  setDrawingChart,
  miniChartModalRef,
  setOpenOrderUpdate,
}) => {

  const dispatch=useDispatch()
  const [headMenu, setHeadMenu] = useState(false);
  const targetRef = useRef();
  const stopLossRef = useRef();
  const modalRef = useRef(null);

  const [tags, setTags] = useState(defaultTag || "");
  // const [entryPrice,setEntryPrice] = useState(0)
  const [opacity, setOpacity] = useState(1);
  const [chartLiveData, setChartLiveData] = useState(null);
  const UserAuthtoken = Index.useSelector((state) => state.UserReducer.token);
  const position = Index.useSelector(
    (state) => state.UserReducer.position
  );
  const {
    timeFrame,
    chartView,
    chartDuration,
    displaySettings,
    selectedScript,
    userLoginDetail,
    isModalDrawingToolOpen,
    isModalDrawingButtonActive,
    theme,
  } = Index.useSelector((state) => state.UserReducer);

  const themeTitle = localStorage.getItem("default-theme");

  let currentPrice = livePrice?.length
    ? livePrice?.[5]
    : stockData?.length
    ? stockData?.[stockData?.length - 1][4]
    : 0;
  let closePrice = livePrice?.length
    ? livePrice[4]
    : stockData?.length
    ? stockData?.[stockData?.length - 1][4]
    : 0;

  useEffect(() => {
    if (livePrice?.length) {
      currentPrice = livePrice[5];
      closePrice = livePrice[4];
    }
  }, [livePrice]);

  async function updateTokenTags(t, tagName) {
    let formattedDate = Index.moment().startOf('minute').valueOf();
    try {
      await Index.DataService(UserAuthtoken).post(
        `/user/stock/stockTagAddAndUpdate`,
        {
          tag: t,
          previousTag: tags,
          script: headerData?.script?.toString(),
          stock_name: headerData?.stock_name || headerData?.name,
          lastTradedPrice: currentPrice,
          target: 12,
          stopLoss: 12,
          entryPrice: currentPrice,
          // exitPrice: 3,
          token: JSON.parse(token),
          tradeType: headerData?.tradeType,
          timeFrame: headerData?.timeFrame,
          timeStamp: setDatefun(formattedDate),
          chartView,
          positionalTimeFrame: headerData?.positionalTimeFrame,
          interval: headerData?.interval,
          intervalValue: headerData?.intervalValue,
        }
      );
    } catch (error) {}

    if (socket) {
      socket.emit("tagging_action", userLoginDetail?._id);
    }


    let payload = {
      token,
      tag: tagName,
      lastTradedPrice: currentPrice,
      entryPrice: currentPrice,
      chartView: chartView,
      interval: headerData?.interval,
      timeStamp: setDatefun(formattedDate),
    }
    if(headerData?.chartView === 'Positional'){
      payload['positionalTimeFrame'] = headerData?.positionalTimeFrame
    }
    handleUpdateStock(payload)
  }

  // async function removeStockTag(t) {
  //   try {
  //     let data = {
  //       token: JSON.parse(token),
  //       tag: t,
  //     };
  //     await Index.DataService(UserAuthtoken).post(
  //       Index.Api.user.removeStock,
  //       data
  //     );
  //     toast.success("Tag removed successfully");
  //   } catch (error) {
  //     // toast.success("Tag updated");
  //   }
  // }

  let allTags;

  if (stockTag?.toLowerCase() === "buy") {
    allTags = ["Exit", "Buy", "Reverse"];
  } else if (stockTag?.toLowerCase() === "sell") {
    allTags = ["Exit", "Sell", "Reverse"];
  } else if (stockTag?.toLowerCase() === "exit") {
    allTags = ["Exit", "Buy", "Sell"];
  } else if (stockTag?.toLowerCase() === "watch") {
    allTags = ["Watch", "Buy", "Sell", "Remove"];
  } else {
    allTags = ["Watch", "Buy", "Sell"];
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateOrder((curr) => ({ ...curr, [name]: value }));
  };

  const printImage = () => {
    const filter = (node) => {
      const exclusionClasses = ["anychart-credits"];
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      );
    };

    const element =
      miniChartModalRef?.current ||
      document.getElementById(`print-${JSON.parse(token)}-${headerData?._id}`);
      
    htmlToImage
      .toJpeg(element, {
        filter: filter,
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `${headerData.stock_name}-${JSON.parse(token)}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((e) => {
        console.log(e, "maulik77");
      });
  };

  const getSingleOrderData = (token) => {
    Index.DataService(UserAuthtoken)
      .get(`/user/get-stock-tag-details?token=${JSON.parse(token)}`)
      .then(({ data }) => {
        if (data?.status === 200) {
          setEditOrderData(data?.data);
        }
      });
  };

  //Shortcut Shift + e to exit
  const handleShortcutExitStock = () => {
    if (activeChart && tags) {
      let data = {
        token,
        interval: headerData?.interval,
        chartView: headerData?.chartView,
      }

      if (headerData?.chartView === "Positional") {
        data["timeFrame"]= headerData?.positionalTimeFrame;
      }

      removeStockTag(tags, data);
    }
  };

  //Shortcut Alt + r to watch
  const handleShortcutReverseStock = () => {
    if (activeChart && ["Buy", "Sell"]?.includes(tags)) {
      updateTokenTags("Reverse", tags === "Sell" ? "Buy" : "Sell");
      setTags(tags === "Buy" ? "Sell" : "Buy");
    }
  };

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    handleShortcutExitStock,
    handleShortcutReverseStock,
  });
  const handleElementClick = (e, xAxis, yAxis) => {
    dispatch(manageModalParameter({ x: e.clientX - xAxis, y: e.clientY + yAxis }))

  };

  const handleToggleDrawingTool = () => {
    dispatch(manageDrawingButton({type: 'Modal'}));
    dispatch(manageDrawingToolActiveDeactive({type: 'Modal', status: true}));
  };

  return (
    <>
      {isModalDrawingButtonActive && isDrawingChart && (
        <DrawChartButton
          newChart={drawingChart && drawingChart?.chart}
          token={drawingChart && drawingChart?.token}
          setNewChart={setDrawingChart}
          isDrawingToolOpen={isModalDrawingToolOpen}
          interval={Constants.annotationTimeFrames[headerData?.interval]}
          isModalTool={true}
        />
      )}
      <Index.Box className="order-header-main">
        {/* <Index.Box className="order-header-left">
                    <Index.Box className="round-chart">

                    </Index.Box>
                    <Index.Box className="stock-name-sec">

                    </Index.Box>
                </Index.Box> */}
        {/* <Index.Box className="header-watch-label">Watch</Index.Box> */}
        <Index.Box
          className={`header-closed-wrapper ${
            isStopLossHit
              ? "red-bg"
              : stockTag?.toLowerCase() === "buy"
              ? "buy-btn-bg"
              : ["sell", "exit"].includes(stockTag?.toLowerCase())
              ? "red-bg"
              : stockTag?.toLowerCase() === "watch"
              ? "blue-bg"
              : "buy-btn-bg"
          }`}
        >
          <Index.Box className={`header-closed-label tag-name`}>
            {isStopLossHit ?? stockTag}
          </Index.Box>
          <Index.Box className={`header-closed-label header-tag-interval`}>
            {headerData?.timeFrame || ""}
          </Index.Box>
          {isStopLossHit && (
            <Index.Box
              className="header-cross-label"
              onClick={() => {
                let payload = {
                  token,
                  interval: headerData?.interval,
                  chartView: headerData?.chartView,
                }
                if(headerData?.chartView === "Positional") {
                  payload["timeFrame"] = headerData?.positionalTimeFrame;
                }

                removeStockTag(stockTag, payload)
              }}
            >
              <img
                className="cross-img"
                alt="cross-img"
                src={Index.Svg.cross}
              />
            </Index.Box>
          )}
        </Index.Box>

        {/* <Index.Box className="round-chart">
          <img
            className="round-chart-img"
            alt="chart-img"
            src={Index.Png.roundChart}
          />
        </Index.Box> */}
        <Index.Box className="order-header-right">
          <Index.Box className="stock-name-sec">
            <Index.Typography
              className="chart-title chart-text"
              variant="p"
              component="p"
            >
              {headerData.stock_name}
            </Index.Typography>
            {displaySettings?.cmp === "on" && (
              <Index.Typography
                className={`chart-text ${
                  +closePrice > +currentPrice ? "red-color" : "green-color"
                }`}
                variant="p"
                component="p"
              >
                <span>₹</span>
                {currentPrice || 0}
                <img
                  className="red-down-aroow"
                  alt="downRedArrow"
                  src={
                    +closePrice > +currentPrice
                      ? Index.Svg.downRedArrow
                      : Index.Svg.upGreenArrow
                  }
                />
              </Index.Typography>
            )}
          </Index.Box>
          <Index.Box className="time-entry-sec">
            <Index.Box className="time-entry-left">
              <img className="calender-img" alt="" src={Index.Svg.calender} />
              <Index.Typography
                className="time-entry-text color-blue"
                variant="p"
                component="p"
              >
                {headerData?.timeFrame || ""}
              </Index.Typography>
              <Index.Typography
                className="time-entry-text"
                variant="p"
                component="p"
              >
                Entry: <span className="color-blue">&nbsp;₹{entryPrice}</span>
              </Index.Typography>
            </Index.Box>
            <Index.Box className="time-entry-right">
              {/* <Index.RoundButton
                className="round-btn download-btn"
                imgsrc={Index.Svg.edit}
                onClick={() => {
                  setOpenModel();
                  setOpenModel({ [JSON.parse(token)]: true });
                }}
              />
              <Index.RoundButton
                className="round-btn download-btn"
                imgsrc={Index.Svg.download}
                onClick={() => printImage()}
              /> */}
              {isDrawingChart && (
                <Index.IconButton
                  className={`tool-icon-btn ${
                    isModalDrawingButtonActive && "active"
                  }`}
                  disableRipple
                  onClick={handleToggleDrawingTool}
                >
                  <img
                    className="tool-icon"
                    src={Index.Svg.toolBox}
                    alt="tool-icon"
                  />
                </Index.IconButton>
              )}

              <Index.Box className="three-dot">
                <img
                  className="dots-img"
                  src={Index.Svg.dots}
                  alt="dots"
                  onClick={(e) => {
                    setHeadMenu(!headMenu);
                    getSingleOrderData(token);
                  }}
                />
                <Index.Box
                  className={
                    headMenu
                      ? "head-menu open-order-head-menu active"
                      : "head-menu open-order-head-menu"
                  }
                >
                  <Index.List className="ul">
                    {allTags?.map((e) => (
                      <Index.ListItem
                        className={`li ${e === tags ? "active" : ""}`}
                        onClick={() => {
                          let removeData = {
                            token,
                            interval: headerData?.interval,
                            chartView: headerData?.chartView,
                          };
                          if(headerData?.chartView === "Positional") {
                            removeData["timeFrame"] = headerData?.positionalTimeFrame;
                          }
                          if (e === "Reverse") {
                            updateTokenTags(
                              e,
                              tags === "Sell" ? "Buy" : "Sell"
                            );
                            setTags(tags === "Sell" ? "Buy" : "Sell");
                          } else if (e === "Remove") {
                            setTags("");
                            removeStockTag("Watch", removeData);
                          } else if (e === "Exit") {
                            setTags("");
                            removeStockTag(tags, removeData, currentPrice);
                          } else {
                            setTags(e);
                            updateTokenTags(e, e);
                          }
                          setHeadMenu((e) => !e);
                        }}
                        key={e}
                      >
                        <Index.Typography
                          className="mint-text"
                          variant="p"
                          component="p"
                        >
                          {e}
                        </Index.Typography>
                      </Index.ListItem>
                    ))}
                    {/* <Index.ListItem
                      className="li"
                      onClick={(e) => {
                        setOpenModel();
                        setOpenModel({ [JSON.parse(token)]: true });
                        setHeadMenu(!headMenu);
                      }}
                    >
                      <Index.Typography
                        className={`mint-text`}
                        variant="p"
                        component="p"
                      >
                        Edit
                      </Index.Typography>
                    </Index.ListItem> */}
                    <Index.ListItem className="li">
                      <Index.Typography
                        className={`mint-text`}
                        variant="p"
                        component="p"
                        onClick={() => {
                          setHeadMenu(!headMenu);
                          printImage();
                        }}
                      >
                        Download
                      </Index.Typography>
                    </Index.ListItem>
                  </Index.List>
                </Index.Box>
              </Index.Box>
              {isDrawingChart && (
                <img
                  className="close-img"
                  onClick={handleClose}
                  src={
                    theme === "Light"
                      ? Index.Svg.closeDark
                      : Index.Svg.closeWhite
                  }
                  alt="closeWhite"
                />
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* {openModel[JSON.parse(token)] ? (
        <Modal
          open={openModel[JSON.parse(token)]}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Index.Box sx={style}>
            <Index.Box>Target</Index.Box>
            <Index.TextField
              value={updateOrder.target}
              onChange={handleChange}
              name="target"
              size="small"
            />
            <Index.Checkbox size="small" />
            <Index.Box>Toploss</Index.Box>
            <Index.TextField
              value={updateOrder.stopLoss}
              onChange={handleChange}
              name="stopLoss"
              size="small"
            />
            <Index.Checkbox size="small" />
            <br />
            <Index.Button onClick={() => deleteLine()}>Delete</Index.Button>
            <Index.Button>Save</Index.Button>
          </Index.Box>
        </Modal>
      ) : (
        ""
      )} */}

      <Modal
        // show={true}
        show={openModel[JSON.parse(token)]}
        setShow={setOpenModel}
        title={editOrderData?.targetAt ? "Target" : "TopLoss"}
        x={(window.innerWidth - 400) / 2}
        y={(window.innerHeight - 400) / 2}
        SVG={Index.Svg.edit}
        width={"400px"}
        opacity={opacity}
        position={position}
        setOpacity={setOpacity}
        html={
          <Index.EditTargetLoss
            headerData={headerData}
            editOrderData={editOrderData}
            setShow={setOpenModel}
            setUpdateOrder={setUpdateOrder}
            token={JSON.parse(token)}
            exchange_timestamp={exchange_timestamp}
            setScriptData={setScriptData}
            scriptData={scriptData}
            orderCurrentChart={orderCurrentChart}
            setAllTagsData={setAllTagsData}
            setOpenOrderUpdate={setOpenOrderUpdate}
            socket={socket}
          />
        }
      />
    </>
  );
};

export default OrderChartHeader;
