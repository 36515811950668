import React, { memo, useState, useEffect, useCallback, useRef } from "react";
import Index from "../../Index";
import { useDispatch, useSelector } from "react-redux";
import { miniChartData } from "../../../redux/webSocket/action";
import ChartOperationsDropdown from "../../../component/common/chart-operations-dropdown/ChartOperationsDropdown";
import { useMemo } from "react";
import { Menu, MenuItem } from "@mui/material";
import CustomMiniChart from "../../../component/custom-chart/mini-chart/CustomMiniChart";
import {
  Subscribe_stock_tokens,
  getPrviousDataOfStock,
  useStockDataListener,
} from "../../../socket";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import Modal from "../../../component/modal/Modal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import {
  getWishListData,
  manageListSettings,
  manageTaggedStocksCount,
} from "../../../redux/user/action";
import { useNavigate } from "react-router-dom";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import CustomCandlestickMiniChart from "../../../component/custom-chart/mini-chart/CustomCandlestickMiniChart";
import CustomAreaChartSingle from "../../../component/custom-chart/custom-single-charts/CustomAreaChartSingle";
import CustomDashedChartSingle from "../../../component/custom-chart/custom-single-charts/CustomDashedChartSingle";
import CustomCendalStickChartSingle from "../../../component/custom-chart/custom-single-charts/CustomCendalStickChartSingle";
import useKeyboardShortcut from "../../../component/common/keyboard-shortcuts/useKeyboardShortcut";
import Constants from "../../../component/common/constants";
import useRowPerPage from "../../../component/hooks/useRowPerPage";
import NoRowsOverlay from "../../../component/common/NoRowsOverlay";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const downloadsType = [
  {
    type: "SVG",
    saveAs: "saveAsSvg",
  },
  {
    type: "PNG",
    saveAs: "saveAsPng",
  },
  {
    type: "JPG",
    saveAs: "saveAsJpg",
  },
  {
    type: "PDF",
    saveAs: "saveAsPdf",
  },
];

function CustomNoRowsOverlay() {
  return (
    <Index.GridOverlay>
      <div style={{ textAlign: "center" }}>No tracking record found</div>
    </Index.GridOverlay>
  );
}

const TrackingWindow = (props) => {
  const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse"];
  const {
    wishListData,
    token,
    currentTag,
    selectedScript,
    displaySettings,
    miniChartSize,
    chartView,
    chartDuration,
    graphType,
    isActiveShortCut,
    isUserLogin,
    twoSideArrowZoom,
    position,
    userLoginDetail,
    sorting,
    theme,
  } = useSelector((state) => state.UserReducer);
  const UserAuthtoken = useSelector((state) => state.UserReducer.token);
  const themeTitle = localStorage.getItem("default-theme");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = Index.useLocation();
  const addRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const dataGridRef = useRef(null);
  const chartData = useSelector(
    (state) => state.WebSocketReducer.miniChartData
  );
  const [loader, setLoader] = useState(true);
  const [miniChartOpen, setminiChartOpen] = useState(false);
  const [allTagsData, setAllTagsData] = useState([]);
  const [scriptData, setScriptData] = useState([]);
  const [headMenuOpen, setHeadMenuOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [activeMiniChartOpen, setActiveMiniChartOpen] = React.useState();
  const [showExitModal, setShowExitModal] = useState(false);
  const [exitResetSuccess, setExitResetSuccess] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [chartLiveData, setChartLiveData] = useState(null);
  const [watchListMenuOpen, setWatchListMenuOpen] = useState(false);
  const miniChartModalRef = useState(null);
  const [dataTableHeight, setDataTableHeight] = useState(0);
  const [rowsPerPage] = useRowPerPage(dataGridRef, dataTableHeight, 80, allTagsData);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });
  const [selectionRow, setSelectionRow] = useState(0);
  const handleClose = () => setminiChartOpen(false);
  const handleOpen = (params) => {
    getAllHistoryData([params], chartView === "Intraday");
    setminiChartOpen(true);
  };

  const [tags, setTag] = React.useState("watch");
  const handleActionClick = useCallback((params) => {
    setRowData({
      token: JSON.parse(params.token),
      stock_name: params.stockName,
      lastTradePrice: params.lastTradePrice,
    });
    setHeadMenuOpen((prevState) => !prevState);
    setWatchListMenuOpen(false);
  }, []);
  const handleWatchListActionClick = useCallback((params) => {
    setRowData(params);
    setWatchListMenuOpen((prevState) => !prevState);
    setHeadMenuOpen(false);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor:
      theme == "Light"
        ? "#ffffff"
        : theme == "Dark"
        ? "#212121"
        : "#283142",
    borderRadius: "10px 10px 0 0",
    border: "none",
  };

  const handleStockView = async (value) => {
    // navigate("/user/view-single-chart", { state: { socketData: token } });
    if (wishListData?.some((e) => +e.token === +value.token)) {
      // window.alert(`${value.name} already in wishlist`);
      if (value?.token) {
        navigate("/user/view-single-chart", {
          state: { stockToken: value?.token },
        });
      }
      // return;
    } else {
      if (value) {
        let wishList = await Index.DataService(token)
          .get(Index.Api.user.getUserWatchList)
          .then((e) => {
            return e?.data?.data?.filter((data) => {
              if (data?.name == selectedScript?.name) {
                return data;
              }
            });
          });
        Index.DataService(token)
          .post(Index.Api.user.addScriptToList, {
            ...value,
            watchListId: wishList?.[0]?._id,
          })
          .then((data) => {
            // if (data.data.status === 200) {
            //   setStockTagCount(data.data.data);
            // }
          });
        dispatch(getWishListData([...wishListData, value]));
      }
    }

    if (value?.token) {
      navigate("/user/view-single-chart", {
        state: { stockToken: value?.token },
      });
    }
  };

  const getStockTagsCount = async () => {
    let params = {
      chartView,
    };
    try {
      const response = await Index.DataService(UserAuthtoken).get(
        Index.Api.user.stockTagCounts,
        { params }
      );
      dispatch(manageTaggedStocksCount(response.data.data));
    } catch (error) {}
  };

  useEffect(() => {
    getStockTagsCount();
  }, [chartView]);

  //Handle Reset Stock
  const handleResetStocks = () => {
    try {
      Index.DataService(UserAuthtoken)
        .post(Index.Api.user.resetStocks, {chartView})
        .then((data) => {
          if (data?.data?.status === 200) {
            setAllTagsData((prev) =>
              prev?.filter((item) => item?.tag !== "Watch")
            );
            getStockTagsCount();
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const getAllTagsData = () => {
    const {reset, order, tags, timeFrame} = sorting[Constants.sortingPath[location?.pathname]];
    const params = {
      chartView,
      sortDate: reset ? reset : '',
      sort: order ? order : '',
      tags: tags,
      sortInterval: timeFrame ? timeFrame : '',
    };
    Index.DataService(UserAuthtoken)
      .get(`${Index.Api.user.getAllTags}?open=true`, { params })
      .then((data) => {
        setAllTagsData(data?.data?.data);
        setScriptData(data?.data?.data);
        setTimeout(()=>{
          setLoader(false)
        },1000)
      })
      .catch((error) => {
        setTimeout(()=>{
          setLoader(false)
        },1000)
      });
  };

  useEffect(() => {
    getAllTagsData();
  }, [chartView, sorting[Constants.sortingPath[location?.pathname]]]);
  
  useEffect(() => {
    if(props?.socket){
      props?.socket.on(`tagging_action_${userLoginDetail?._id}`, (data) => {
        getAllTagsData();
        getStockTagsCount();
      });
    }
  }, [props?.socket]);

  useEffect(() => {
    if (props.socket && allTagsData?.length && chartView === "Intraday") {
      allTagsData
        ?.slice(
          (paginationModel?.page + 1) * paginationModel?.pageSize -
            paginationModel?.pageSize,
          (paginationModel?.page + 1) * paginationModel?.pageSize
        )
        ?.forEach((token) => {
          props.socket.emit("joinRoom", `token_${JSON.parse(token?.token)}`);

          props.socket.on(`token_${JSON.parse(token?.token)}`, (data) => {
            setChartLiveData(data);
          });
        });
    }
    return () => {
      if (props.socket && allTagsData?.length) {
        allTagsData?.forEach((token) => {
          props.socket.emit("leaveRoom", `token_${token?.token}`);
        });
      }
      // setChartLiveData(null);
    };
  }, [props.socket, allTagsData, paginationModel, chartView]);

  let isWeekendDay = [6, 7]?.includes(Index.moment().isoWeekday());
  let prevCloseDate = usePreviousDateCalculator(isWeekendDay ? 2 : 1);

  const row = useMemo(() => {
    return allTagsData?.map((item, index) => {
      if (
        chartLiveData &&
        JSON.parse(item.token) == JSON.parse(chartLiveData.token)
      ) {
        item["chartLiveData"] = chartLiveData;
        item["livePrice"] = chartLiveData.tick;
        item["lastTradePrice"] = chartLiveData.tick[5];
        item["closePrice"] = chartLiveData.tick[6];
      }

      if (!chartLiveData && item?.stockData && item?.stockData?.length) {
        const lastDateData = item?.stockData?.filter(
          (data) =>
            Index.moment(new Date(data[0])).format("YYYY-MM-DD") ==
            Index.moment(prevCloseDate).format("YYYY-MM-DD")
        );

        let prevClosePrice =
          lastDateData.length > 0
            ? lastDateData[lastDateData.length - 1][4]
            : item?.previousClosePrice;

        item["lastTradePrice"] =
          item?.stockData[item?.stockData?.length - 1][4] || 0;
        item["closePrice"] = prevClosePrice || 0;
      }
      return {
        _id: item?._id,
        rowIndex: index - paginationModel?.page * rowsPerPage,
        rowId: index + 1,
        tag: item?.tag || "-",
        CMP: item?.lastTradePrice || "-",
        entry: item?.entryPrice || "-",
        stopLoss: item?.topLossAt?.price || "-",
        target: item?.targetAt?.price || "-",
        chargepoint: `${
          item?.closePrice > item?.lastTradePrice
            ? (item?.lastTradePrice - item?.closePrice).toFixed(2)
            : item?.closePrice !== item?.lastTradePrice
            ? `+${(item?.lastTradePrice - item?.closePrice).toFixed(2)}`
            : 0
        } (${
          item?.lastTradePrice && item?.closePrice
            ? `${(
                ((item?.lastTradePrice - item?.closePrice) / item?.closePrice) *
                100
              ).toFixed(2)}%`
            : "0%"
        })`,
        script: item?.stock_name || "-",
        stockData: item?.stockData || [],
        livePrice: item?.livePrice,
        chartLiveData: item?.chartLiveData,
        tradeType: item?.tradeType || "",
        chartView: item?.chartView || "",
        timeFrame: item?.timeFrame || "",
        positionalTimeFrame: item?.positionalTimeFrame || null,
        interval: item?.interval || null,
        intervalValue: item?.intervalValue || null,
        id: index + 1,
        dateTime: Index.moment(item?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        miniChart: {
          token: item?.token,
          lastTradePrice: "",
        },
        token: item?.token,
        action: [
          {
            token: item?.token,
            value: Index.Svg.roundPlus,
          },
          {
            token: item?.token,
            stockName: item?.stock_name || "-",
            value: Index.Svg.whiteDots,
            currentTag: item?.tag || "-",
          },
        ],
      };
    });
  }, [allTagsData, chartLiveData]);
  
  //handle Tag update
  const handleTagUpdate = (tagName, token) => {
    if (!tagName) {
      setAllTagsData((prev) =>
        prev?.filter((item) => JSON.parse(item?.token) != JSON.parse(token))
      );
    }
    setAllTagsData((prev) =>
      prev?.map((item) => {
        if (JSON.parse(item?.token) == JSON.parse(token)) {
          return {
            ...item,
            tag: tagName,
            timeFrame: Constants.shortInterval[chartDuration[chartView]?.interval],
          };
        }
        return item;
      })
    );
  };

  //Handle add to watchList
  const handleAddToWatchList = useCallback(async (value, watchListId) => {
    try {
      if (value?.token) {
        const response = await Index.DataService(token).post(
          Index.Api.user.addScriptToList,
          {
            ...value,
            watchListId,
          }
        );
        // setStockTagCount(data.data.data);
        // dispatch(getWishListData([...wishListData, value]));
        setWatchListMenuOpen((prev) => !prev);
      }
    } catch (error) {}
  }, []);
  // action handler removeStockTag fun and updateToken fun
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setHeadMenuOpen(false);
      }
      if (addRef.current && !addRef.current.contains(event.target)) {
        setWatchListMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownMenuRef, addRef]);

  const updateTokenTags = useCallback(async (t, rowData) => {
    try {
      let formattedDate = Index.moment().startOf("minute").valueOf();
      const response = await Index.DataService(UserAuthtoken).post(
        `/user/stock/stockTagAddAndUpdate`,
        {
          tag: t,
          stock_name: rowData?.stock_name || "-",
          previousTag: rowData?.tag,
          script: selectedScript?.script?.toString(),
          lastTradedPrice: rowData?.lastTradePrice,
          target: 12,
          stopLoss: 12,
          entryPrice: rowData?.lastTradePrice,
          // exitPrice: 3,
          token: JSON.parse(rowData?.token),
          tradeType: rowData?.tradeType,
          timeFrame: rowData?.timeFrame,
          timeStamp: setDatefun(formattedDate),
          chartView,
          positionalTimeFrame: rowData?.positionalTimeFrame,
          interval: rowData?.interval,
          intervalValue: rowData?.intervalValue
        }
      );
    } catch (error) {}

    if (props?.socket) {
      props?.socket.emit("tagging_action", userLoginDetail?._id);
    }

  }, []);

  const removeStockTag = useCallback(async (t, payload) => {
    try {
      let data = {
        token: JSON.parse(payload?.token),
        tag: t,
        lastTradedPrice: rowData?.lastTradePrice,
        chartView: payload?.chartView,
        timeFrame: payload?.positionalTimeFrame,
        interval: payload?.interval,
      };
      const response = await Index.DataService(UserAuthtoken).post(
        Index.Api.user.removeStock,
        data
      );

      getStockTagsCount();
      if (props?.socket) {
        props?.socket.emit("tagging_action", userLoginDetail?._id);
      }
    } catch (error) {}
  }, []);

  const getColorClassByTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "buy":
        return "green-buy-btn";
      case "sell":
        return "red-buy-btn";
      case "exit":
        return "red-buy-btn";
      case "watch":
        return "blue-watch-btn";
      default:
        return "";
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S no.",
      width: 74,
      hideable: false,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "script",
      headerName: "Script",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "entry",
      headerName: "Entry",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stopLoss",
      headerName: "Stop Loss",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      padding: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "target",
      headerName: "Target",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      padding: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 100,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Index.Box className="list-label-main">
          <Index.Button
            className={`${getColorClassByTag(params?.value)} label-btn`}
            disableRipple
          >
            {params?.value}
          </Index.Button>
          <Index.Button
            className={`${getColorClassByTag(params?.value)} timeframe-btn`}
            disableRipple
          >
            {params?.row?.timeFrame}
          </Index.Button>
        </Index.Box>
        // <Index.Button
        //   className={
        //     params.value.toLowerCase() === "buy"
        //       ? "green-buy-btn"
        //       : params.value.toLowerCase() === "sell" ||
        //         params.value.toLowerCase() === "exit"
        //       ? "red-buy-btn"
        //       : params.value.toLowerCase() === "watch"
        //       ? "blue-watch-btn"
        //       : "green-buy-btn"
        //   }
        //   disableRipple
        // >
        //   {params.value}
        // </Index.Button>
      ),
    },
    {
      field: "CMP",
      headerName: "CMP",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "miniChart",
      headerName: "Mini Chart",
      type: "number",
      width: miniChartSize?.width,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Index.Button
          onClick={() => {
            handleOpen(params?.row);
            setActiveMiniChartOpen(params?.value?.token);
          }}
          sx={{ padding: 0 }}
          disableRipple
        >
          {/* <CustomMiniChart
            chartData={params?.row?.stockData}
            token={JSON.parse(params?.value?.token)}
          /> */}
          {graphType[theme][chartView] === 3 ? (
            <CustomCandlestickMiniChart
              rowData={params?.row}
              chartData={params?.row?.stockData}
              token={JSON.parse(params?.value?.token)}
            />
          ) : (
            <CustomMiniChart
              rowData={params?.row}
              chartData={params?.row?.stockData}
              token={JSON.parse(params?.value?.token)}
              chartType={graphType[theme][chartView]}
            />
          )}
        </Index.Button>
      ),
    },
    {
      field: "chargepoint",
      headerName: "Change Points (%)",
      type: "number",
      width: 230,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
      renderCell: (params) => {
        return (
          <>
            <Index.Box
              className="MuiDataGrid-cellContent"
              sx={{ color: params?.value?.[0] == "+" ? "#008000" : "red" }}
            >
              {params.value}
            </Index.Box>
          </>
        );
      },
    },
    {
      field: "dateTime",
      headerName: "Date & Time",
      type: "number",
      width: 230,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
    },
    {
      field: "action",
      headerName: "Actions",
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <DropdownMenuAction
          className="drop-down"
          row={params.row}
          handleTagUpdate={handleTagUpdate}
          removeStockTag={removeStockTag}
          updateTokenTags={updateTokenTags}
          handleAddToWatchList={handleAddToWatchList}
        />
      ),
    },
  ];

  const fromDate = usePreviousDateCalculator(
    chartView === "Intraday" ? 1 : chartDuration[chartView]?.timeFrame - 1
  );
  const getAllHistoryData = async (dataLength, live) => {
    const tokens = dataLength?.map((item) => ({ token: JSON.parse(item?.token), type: item?.exchange_type }));
    const minutesToLess =
      Constants.chartInterval[chartDuration[chartView]?.interval]?.value *
      60000;
    const formData = {
      tokens: tokens,
      interval:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.interval,
      fromDate,
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.offset,
    };

    const formDataIntraday = {
      tokens: tokens,
      interval:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.interval,
      fromDate: Index.moment().format("YYYY-MM-DD"),
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.offset,
      previousDate: prevCloseDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(UserAuthtoken).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
          ]
        : [
            Index.DataService(UserAuthtoken).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
            Index.DataService(UserAuthtoken).post(
              Index.Api.user.getAllHistoricalData,
              formData
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        [key]: combinedData[key],
      }));

      if (formattedCombinedData.length) {
        setAllTagsData((prevData) =>
          prevData?.map((ele) => {
            const token = JSON.parse(ele?.token);
            const item = formattedCombinedData.find((data) => data[token]);

            if (item && item[token]?.length) {
              const format = item[token].map((el) => [
                Index.moment(el[0]).startOf("minute").valueOf() - minutesToLess,
                +el[1],
                +el[2],
                +el[3],
                +el[4],
              ]);
              return {
                ...ele,
                stockData: format,
                previousClosePrice: previousDateData[token],
              };
            } else {
              return ele;
            }
          })
        );
      }
    } catch (error) {}
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(() => resolve, ms));

  const callAllHistorical = async () => {
    let dataLength = allTagsData?.slice(
      (paginationModel?.page + 1) * paginationModel?.pageSize -
        paginationModel?.pageSize,
      (paginationModel?.page + 1) * paginationModel?.pageSize
    );

    if (dataLength?.length) {
      const chunkData = _.chunk(dataLength, 5);
      chunkData?.forEach(async (item) => {
        getAllHistoryData(item, chartView === "Intraday");
        await delay(500);
      });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      callAllHistorical();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [scriptData, paginationModel, chartDuration, chartView]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  const handleKeyDown = (e) => {
    const key = e.key;

    const startIndex =
      (paginationModel?.page + 1) * paginationModel?.pageSize -
      paginationModel?.pageSize;
    const endIndex = (paginationModel?.page + 1) * paginationModel?.pageSize;
    const rowData = row?.slice(startIndex, endIndex);

    const firstId = rowData[0];
    const lastId = rowData[rowData?.length - 1];

    if (key === "ArrowDown") {
      if (!selectionRow) {
        setSelectionRow(firstId?.id);
      }

      if (selectionRow && selectionRow < lastId?.id) {
        setSelectionRow((prev) => prev + 1);
      }
    } else if (key === "ArrowUp") {
      if (selectionRow && selectionRow > firstId?.id) {
        setSelectionRow((prev) => prev - 1);
      }
    } else if (key === "ArrowRight") {
      if (paginationModel?.page < allTagsData?.length / rowsPerPage) {
        setSelectionRow(0);
        const data = {
          ...paginationModel,
          page: paginationModel?.page + 1,
        };
        setPaginationModel(data);
        dispatch(manageListSettings(data));
      }
    } else if (key === "ArrowLeft") {
      if (paginationModel?.page > 0) {
        setSelectionRow(0);
        const data = {
          ...paginationModel,
          page: paginationModel?.page - 1,
        };
        setPaginationModel(data);
        dispatch(manageListSettings(data));
      }
    }

    if (key === "Tab") {
      setSelectionRow(0);
    }
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isActiveShortCut, isUserLogin, selectionRow, paginationModel?.page]);

  const selectedData = useMemo(() => {
    return row?.length > 0
      ? row?.find((item) => selectionRow == item?.id)
      : null;
  }, [selectionRow]);

  //Shortcut Alt + B to buy
  const handleShortcutBuyStock = () => {
    if (selectionRow > 0 && selectedData && !selectedData?.tag) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        tradeType: selectedData?.tradeType,
        timeFrame: selectedData?.timeFrame,
        positionalTimeFrame: selectedData?.positionalTimeFrame,
        interval: selectedData?.interval,
        intervalValue: selectedData?.intervalValue,
      };
      updateTokenTags("Buy", data);
      handleTagUpdate("Buy", selectedData?.token);
    }
  };

  //Shortcut Alt + s to sell
  const handleShortcutSellStock = () => {
    if (selectionRow > 0 && selectedData && !selectedData?.tag) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        tradeType: selectedData?.tradeType,
        timeFrame: selectedData?.timeFrame,
        positionalTimeFrame: selectedData?.positionalTimeFrame,
        interval: selectedData?.interval,
        intervalValue: selectedData?.intervalValue,
      };
      updateTokenTags("Sell", data);
      handleTagUpdate("Sell", selectedData?.token);
    }
  };

  //Shortcut Alt + w to watch
  const handleShortcutWatchStock = () => {
    if (selectionRow > 0 && selectedData && !selectedData?.tag) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        tradeType: selectedData?.tradeType,
        timeFrame: selectedData?.timeFrame,
        positionalTimeFrame: selectedData?.positionalTimeFrame,
        interval: selectedData?.interval,
        intervalValue: selectedData?.intervalValue,
      };
      updateTokenTags("Watch", data);
      handleTagUpdate("Watch", selectedData?.token);
    }
  };

  //Shortcut Alt + r to reverse
  const handleShortcutReverseStock = () => {
    if (
      selectionRow > 0 &&
      selectedData &&
      ["Buy", "Sell"]?.includes(selectedData?.tag)
    ) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        tradeType: selectedData?.tradeType,
        timeFrame: selectedData?.timeFrame,
        positionalTimeFrame: selectedData?.positionalTimeFrame,
        interval: selectedData?.interval,
        intervalValue: selectedData?.intervalValue,
      };
      let tag = selectedData?.tag === "Buy" ? "Sell" : "Buy";
      updateTokenTags("Reverse", data);
      handleTagUpdate(tag, selectedData?.token);
    }
  };

  //Shortcut Shift + e to exit
  const handleShortcutExitStock = () => {
    
    if (
      selectionRow > 0 &&
      selectedData &&
      ["Buy", "Sell"]?.includes(selectedData?.tag)
    ) {
      const data = {
        token: JSON.parse(selectedData?.token),
        chartView: selectedData?.chartView,
        timeFrame: selectedData?.positionalTimeFrame,
        interval: selectedData?.interval,
      }
      removeStockTag(selectedData?.tag, data);
      handleTagUpdate("", selectedData?.token);
    }
  };

  //Shortcut Alt + r to remove watched tag
  const handleShortcutRemoveStock = () => {
    if (selectionRow > 0 && selectedData && selectedData?.tag === "Watch") {
      const data = {
        token: JSON.parse(selectedData?.token),
        chartView: selectedData?.chartView,
        timeFrame: selectedData?.positionalTimeFrame,
        interval: selectedData?.interval,
      }
      removeStockTag("Watch", data);
      handleTagUpdate("", selectedData?.token);
    }
  };

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    handleShortcutBuyStock,
    handleShortcutSellStock,
    handleShortcutWatchStock,
    handleShortcutReverseStock,
    handleShortcutExitStock,
    handleShortcutRemoveStock,
  });

  //Data table height
  let mainContentElement = document.querySelector(".main-content-sec");
  let buttonSecElement = document.querySelector(
    ".tracking-window-header-btn-flex"
  );

  useEffect(() => {
    if (mainContentElement && buttonSecElement) {
      const mainHeight = mainContentElement.clientHeight;
      const buttonSecHeight = buttonSecElement.clientHeight;
      setDataTableHeight(mainHeight - buttonSecHeight - 25);
    }
  }, [mainContentElement, buttonSecElement, twoSideArrowZoom]);

  return (
    <>
      <Index.Box className="dashboard-wrapper tracking-window">
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } ${twoSideArrowZoom && "hide-main-content-margin"}`}
        >
          <Index.Box className="tracking-window-header-btn-flex">
            <Index.PrimaryButton
              className="primary-btn index-btn"
              btnLabel="Exit"
              onClick={() => {
                setShowExitModal(!showExitModal);
              }}
            ></Index.PrimaryButton>
            <Index.PrimaryButton
              className="primary-btn index-btn"
              btnLabel="Reset"
              onClick={handleResetStocks}
            />
          </Index.Box>
          <Index.Box
            // className={`table-sec ${
            //   row.length < 12 ? "transaction-table" : ""
            // }`}
            className="table-sec"
          >
            {/* {row && row.length > 0 ? ( */}
              <Index.Box
                sx={{ width: "100%", height: dataTableHeight }}
                className="tracking-window-table user-tagged-scripts-performance"
              >
                <Index.DataGrid
                  ref={dataGridRef}
                  rows={loader ? [] : row}
                  sx={{
                    "& .MuiDataGrid-cell[data-field='miniChart']": {
                      padding: 0,
                    },
                  }}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  components={{
                    NoRowsOverlay: ()=> <Index.NoRowsOverlay loader={loader}/>, 
                  }}
                  pageSizeOptions={[1000]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={({ page, pageSize }) => {
                    setPaginationModel({ page, pageSize });
                    dispatch(manageListSettings({ page, pageSize }));
                  }}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectionRow(newRowSelectionModel[0]);
                  }}
                  rowSelectionModel={selectionRow > 0 ? [selectionRow] : []}
                  rowSelection
                  localeText={{
                    footerRowSelected: () => null,
                    MuiTablePagination: {
                      labelDisplayedRows: ({ from, to, count, estimated }) => {
                        if (!estimated) {
                          return `${from} – ${to} of ${
                            count !== -1 ? count : `${to}`
                          }`;
                        }
                        return `${from} – ${to} of ${
                          count !== -1
                            ? count
                            : `${estimated > to ? estimated : to}`
                        }`;
                      },
                    },
                  }}
                  // pagination={row.length > 12}
                  rowHeight={80}
                />
              </Index.Box>
            {/* ) : (
              <Index.Box
                sx={{ textAlign: "center", padding: "20px" }}
                className="text-data"
              >
                No record found
              </Index.Box>
            )} */}
          </Index.Box>
        </Index.Box>

        {miniChartOpen && (
          <>
            {" "}
            <Index.Box>
              <Index.Modal
                open={miniChartOpen}
                onClose={() => {
                  handleClose();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                ref={miniChartModalRef}
              >
                <Index.Box sx={style} className="mini-chart-modal-box">
                  <Index.Box className="modal-header-sec custom-modal-header">
                    <Index.ChartHeader
                      index={JSON.parse(activeMiniChartOpen)}
                      tags={
                        allTagsData?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.tag
                      }
                      setTags={setTag}
                      allTags={allTags}
                      headerData={{
                        stock_name: allTagsData?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.stock_name,
                        stockData: allTagsData?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.stockData,
                      }}
                      chartData={
                        allTagsData?.filter(
                          (data) =>
                            JSON.parse(data?.token) ==
                            JSON.parse(activeMiniChartOpen)
                        )?.[0]?.stockData
                      }
                      onClose={() => {
                        handleClose();
                      }}
                      handleTagUpdate={handleTagUpdate}
                      downloadsType={downloadsType}
                      live={chartView === "Intraday"}
                      previousClosePrice={
                        allTagsData?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.previousClosePrice
                      }
                      miniChartModalRef={miniChartModalRef}
                      chartLiveData={
                        allTagsData?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.chartLiveData
                      }
                      isModalChart={true}
                      socket={props?.socket}
                    ></Index.ChartHeader>
                  </Index.Box>
                  <Index.Box className="modal-body-sec">
                    {graphType[theme][chartView] === 0 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomLineChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                    {graphType[theme][chartView] === 1 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomAreaChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                    {graphType[theme][chartView] === 2 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomDashedChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                    {graphType[theme][chartView] === 3 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomCendalStickChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            allTagsData?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                    {/* <Index.Box className="chart-body">
                      <CustomLineChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        liveData={
                          allTagsData?.filter(
                            (data) =>
                              data?.token == JSON.parse(activeMiniChartOpen)
                          )?.[0]?.stockData
                        }
                        stockData={
                          allTagsData?.filter(
                            (data) =>
                              data?.token == JSON.parse(activeMiniChartOpen)
                          )?.[0]?.stockData
                        }
                        index={activeMiniChartOpen}
                        stockToken={activeMiniChartOpen}
                      />
                    </Index.Box> */}
                  </Index.Box>
                </Index.Box>
              </Index.Modal>
            </Index.Box>{" "}
          </>
        )}
      </Index.Box>

      <Modal
        show={showExitModal}
        setShow={setShowExitModal}
        title={"Exit From Stock"}
        x={window?.innerWidth / 2 - 180}
        y={window?.innerHeight / 2 - 130}
        opacity={opacity}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        setOpacity={setOpacity}
        width={"407px"}
        html={
          <ExitStockModal
            setShowExitModal={setShowExitModal}
            allTagsData={allTagsData}
            setAllTagsData={setAllTagsData}
            getStockTagsCount={getStockTagsCount}
          />
        }
      />
    </>
  );
};

export default memo(TrackingWindow);

const ExitStockModal = ({
  setShowExitModal,
  allTagsData,
  setAllTagsData,
  getStockTagsCount,
}) => {
  const { token, chartView, chartDuration } = useSelector((state) => state.UserReducer);
  const allStockTypes = [
    { id: 1, name: "Equity", value: "NSE" },
    { id: 2, name: "F&O", value: "NFO" },
    { id: 3, name: "Commodity", value: "MCX" },
  ];

  //Validation
  const validationSchemaExitStock = yup.object().shape({
    category: yup.string().required("Please select category"),
  });

  const handleExitFromStock = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("exchange_type", values?.category);
    urlencoded.append("chartView", chartView);
    Index.DataService(token)
      .post(Index.Api.user.exitStocks, urlencoded)
      .then((data) => {
        if (data?.data?.status === 200) {
          let tokensList = allTagsData?.map((ele) => {
            if (ele?.exchange_type == values?.category) {
              return JSON.parse(ele?.token);
            }
          });
          setAllTagsData((prev) =>
            prev?.filter(
              (item) => !tokensList?.includes(JSON.parse(item?.token))
            )
          );
          getStockTagsCount();
        }
      })
      .catch((error) => {
        // setShowExitModal(false);
      });
    setShowExitModal(false);
  };
  return (
    <>
      <Index.Box className="body-p sorting-body-wrapper">
        <Index.Box className="content-wrapper">
          <Formik
            validationSchema={validationSchemaExitStock}
            initialValues={{ category: "" }}
            onSubmit={handleExitFromStock}
          >
            {({ values, errors, handleBlur, handleChange, touched }) => (
              <Form>
                <Index.Box className="name-wrap name-warp-tracking-window">
                  <Index.Box className="cus-input-grp exit-stock-modal-box">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Category
                    </Index.Typography>
                    <Index.Box className="form-group">
                      <Index.FormControl className="form-control-user drop-form-control">
                        <Index.Select
                          fullWidth
                          name="category"
                          className="form-control"
                          displayEmpty
                          value={values?.category}
                          placeholder="User Type"
                          // onChange={handleChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {values?.category === "" ||
                          values?.category === undefined ? (
                            <Index.MenuItem
                              disabled
                              value=""
                              className="form-control"
                            >
                              Category
                            </Index.MenuItem>
                          ) : null}
                          {allStockTypes?.map((ele) => {
                            return (
                              <Index.MenuItem
                                key={ele?.id}
                                value={ele?.value}
                                // name="admin"
                                className="drop-menuitem game-item"
                              >
                                {ele?.name}
                              </Index.MenuItem>
                            );
                          })}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormHelperText error className="error-text">
                        {errors.category && touched.category
                          ? errors.category
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.PrimaryButton
                    className="primary-btn save-changes-btn"
                    btnLabel="Exit"
                    type="submit"
                  />
                </Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
      {/* </Index.Box> */}
    </>
  );
};
const DropdownMenuAction = ({
  row,
  handleTagUpdate,
  removeStockTag,
  updateTokenTags,
  handleAddToWatchList,
}) => {
  const { id, tag, token, CMP, script, timeFrame, tradeType, chartView, positionalTimeFrame, interval, intervalValue } = row;
  const { customScripts } = Index.useSelector((state) => state.UserReducer);
  const [allTags, setAllTags] = useState([
    "Exit",
    "Watch",
    "Buy",
    "Sell",
    "Remove",
    "Reverse",
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [watchListAnchorEl, setWatchListAnchorEl] = useState(null);

  //Tag menu controllers
  const handleTagMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTagMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTagMenuActionClick = (item, tag, token) => {
    let rowData = {
      stock_name: script,
      lastTradePrice: CMP,
      token: token,
      tag: tag,
      positionalTimeFrame,
      interval,
      intervalValue,
      timeFrame,
      tradeType,
      chartView,
    };
    if (item === "Reverse") {
      updateTokenTags("Reverse", rowData);
      handleTagUpdate &&
        handleTagUpdate(tag === "Sell" ? "Buy" : "Sell", token);
    } else if (item === "Remove") {
      removeStockTag("Watch", rowData);
      handleTagUpdate && handleTagUpdate("", token);
    } else if (item === "Exit") {
      removeStockTag(tag, rowData);
      handleTagUpdate && handleTagUpdate("", token);
    } else {
      updateTokenTags(item, rowData);
      handleTagUpdate && handleTagUpdate(item, token);
    }
    handleTagMenuClose();
  };

  const handleFilterTag = (item) => {
    if (!tag) {
      return ["Watch", "Buy", "Sell"].includes(item);
    }

    if (tag?.toLowerCase() === "watch") {
      return ["Watch", "Buy", "Sell", "Remove"].includes(item);
    }

    if (tag?.toLowerCase() === "buy") {
      return ["Exit", "Buy", "Reverse"].includes(item);
    }

    if (tag?.toLowerCase() === "sell") {
      return ["Exit", "Sell", "Reverse"].includes(item);
    }

    if (tag?.toLowerCase() === "exit") {
      return ["Exit", "Buy", "Sell"].includes(item);
    }
  };

  //WatchList controllers
  const handleWatchListClick = (event) => {
    setWatchListAnchorEl(event.currentTarget);
  };

  const handleWatchListClose = () => {
    setWatchListAnchorEl(null);
  };

  const handleWatchListActionClick = (data, id) => {
    handleAddToWatchList(data, id);
    handleWatchListClose();
  };

  return (
    <Index.Box className="my-Menu-Component">
      <Index.Box className="d-flex">
        {/* <button
          className="table-gray-btn menu-three-dot-btn"
          disableRipple
          onClick={handleWatchListClick}
        >
          <img
            className="action-btn-img"
            alt="add-action-icon"
            src={Index.Svg.roundPlus}
          />
        </button> */}
        <button
          className="table-gray-btn menu-three-dot-btn"
          disableRipple
          onClick={handleTagMenuClick}
        >
          <img
            className="action-btn-img"
            alt="add-action-icon"
            src={Index.Svg.whiteDots}
          />
        </button>
      </Index.Box>
      <Index.Box className="menu-list-main">
        {/* WatchList Menu Dropdown */}
        <Menu
          id="simple-menu2"
          className="watchlist-menu analysis-menu"
          anchorEl={watchListAnchorEl}
          keepMounted
          open={Boolean(watchListAnchorEl)}
          onClose={handleWatchListClose}
        >
          {customScripts?.length ? (
            customScripts?.map((item, index) => (
              <MenuItem
                key={`script-${index}`}
                className={`${tag === item?.name && "li-active"}`}
                onClick={() => {
                  handleWatchListActionClick({ token }, item?._id);
                }}
              >
                {item?.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem className="">No Data</MenuItem>
          )}
        </Menu>

        {/* Tag Menu Dropdown */}
        <Menu
          id="simple-menu"
          className="tag-menu analysis-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleTagMenuClose}
        >
          {allTags.filter(handleFilterTag).map((item) => (
            <MenuItem
              key={`${item}`}
              className={`${tag === item && "li-active"}`}
              onClick={() => handleTagMenuActionClick(item, tag, token)}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      </Index.Box>
    </Index.Box>
  );
};
