import React, { useState, useEffect, useMemo, memo, useRef } from "react";
import Index from "../../../Index";
import PropTypes from "prop-types";
import Modal from "../../../../component/modal/Modal";
import AutoWidthHeight from "../../../../component/common/AutoWidthHeight";
import FileSaver from "file-saver";
import * as XLSX from "xlsx-color";
import { manageModalParameter } from "../../../../redux/user/action";
import { useDispatch } from "react-redux";
import Constants from "../../../../component/common/constants";
import useRowPerPage from "../../../../component/hooks/useRowPerPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columns = [
  {
    field: "id",
    headerName: "Sr.",
    width: 70,
    align: "center",
    headerAlign: "center",
    hideable: false,
    editable: false,
    sortable: false,
    filterable: false,
  },
  {
    field: "script",
    headerName: "Script",
    // width: 360,
    width: 220,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "tag",
    headerName: "Trade",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Index.Button
        className={
          params.value.toLowerCase() === "buy"
            ? "green-buy-btn"
            : params.value.toLowerCase() === "sell"
            ? "red-buy-btn"
            : params.value.toLowerCase() === "watch"
            ? "blue-watch-btn"
            : "green-buy-btn"
        }
        disableRipple
      >
        {params.value}
      </Index.Button>
    ),
  },
  {
    field: "timeFrame",
    headerName: "Time Frame",
    // width: 180,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "entry",
    headerName: "Entry",
    type: "number",
    // width: 180,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "entryDateTime",
    headerName: "Entry Date & Time",
    type: "number",
    width: 250,
    // width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
    padding: 0,
    renderCell: (params) => (
      <Index.Box>
        <Index.Typography>
          {Index.moment(params?.value).format("DD/MM/YYYY HH:mm:ss")}
        </Index.Typography>
      </Index.Box>
    ),
  },
  {
    field: "hitStatus",
    headerName: "Achieved",
    // width: 360,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "exit",
    headerName: "Exit",
    type: "number",
    // width: 190,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
    padding: 0,
  },
  {
    field: "exitDateTime",
    headerName: "Exit Date & Time",
    type: "number",
    // width: 180,
    width: 250,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
    padding: 0,
    renderCell: (params) => (
      <Index.Box>
        <Index.Typography>
          {Index.moment(params?.value).format("DD/MM/YYYY HH:mm:ss")}
        </Index.Typography>
      </Index.Box>
    ),
  },
  {
    field: "profit",
    headerName: "Profit & loss in Points (%)",
    type: "number",
    // width: 295,
    width: 280,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
    padding: 0,
    renderCell: (params) => {
      return (
        <>
          <Index.Box
            className="MuiDataGrid-cellContent"
            sx={{
              color:
                params?.value?.[0] == "-"
                  ? "red"
                  : params?.value?.[0] == "+"
                  ? "#008000"
                  : "",
            }}
          >
            {params.value}
          </Index.Box>
        </>
      );
    },
  },
];

const OrderColumns = [
  {
    field: "id",
    headerName: "Sr.",
    width: 70,
    hideable: false,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "script",
    headerName: "Script",
    // width: 390,
    width: 220,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "tag",
    headerName: "Trade",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Index.Button
        className={
          params.value.toLowerCase() === "buy"
            ? "green-buy-btn"
            : ["sell", "exit"].includes(params.value.toLowerCase())
            ? "red-buy-btn"
            : params.value.toLowerCase() === "watch"
            ? "blue-watch-btn"
            : "green-buy-btn"
        }
        disableRipple
      >
        {params.value}
      </Index.Button>
    ),
  },
  {
    field: "timeFrame",
    headerName: "Time Frame",
    // width: 180,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "entry",
    headerName: "Entry",
    type: "number",
    // width: 230,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "StopLoss",
    headerName: "Stop Loss",
    type: "number",
    // width: 230,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Target",
    headerName: "Target",
    type: "number",
    // width: 200,
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "entryDateTime",
    headerName: "Entry Date & Time ",
    type: "number",
    width: 268,
    editable: false,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
  },
];

const performanceColumnHeader = {
  id: "Sr.",
  script: "Script",
  tag: "Trade",
  timeFrame: "Time Frame",
  entry: "Entry",
  entryDateTime: "Entry Date & Time",
  hitStatus: "Achieved",
  exit: "Exit",
  exitDateTime: "Exit Date & Time",
  profit: "Profit & loss in Points (%)",
};
const orderColumnHeader = {
  id: "Sr.",
  script: "Script",
  tag: "Trade",
  timeFrame: "Time Frame",
  entry: "Entry",
  StopLoss: "Stop Loss",
  Target: "Target",
  entryDateTime: "Entry Date & Time",
};

const UserTaggedScripts = () => {
  // state start
  const dispatch = useDispatch();
  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);
  const {
    displaySettings,
    chartView,
    twoSideArrowZoom,
    sorting,
  } = Index.useSelector((state) => state.UserReducer);

  const location = Index.useLocation();
  const userId = location?.state?.row?._id;

  const { height, width } = AutoWidthHeight();
  const dataGridRef = useRef(null);
  const [reportModal, setreportModal] = useState(false);
  const position = Index.useSelector((state) => state.UserReducer.position);
  const [value, setValue] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [allTagsData, setAllTagsData] = useState([]);
  const [tempAllTagsData, setTempAllTagsData] = useState([]);
  const [tab, setTab] = useState(0);
  const [count, setCount] = useState(0);
  const [pageValue, setPageValue] = useState(1);
  const [orderReportData, setOrderReportData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const [dataTableHeight, setDataTableHeight] = useState(0);
  const [rowsPerPage] = useRowPerPage(dataGridRef, dataTableHeight, 52, pageData);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });
  

  const [orderColumnVisibilityModel, setOrderColumnVisibilityModel] = useState({
    id: true,
    script: true,
    tag: true,
    timeFrame: true,
    entry: true,
    StopLoss: true,
    Target: true,
    entryDateTime: true,
  });
  const [
    performanceColumnVisibilityModel,
    setPerformanceColumnVisibilityModel,
  ] = useState({
    id: true,
    script: true,
    tag: true,
    timeFrame: true,
    entry: true,
    entryDateTime: true,
    hitStatus: true,
    exit: true,
    exitDateTime: true,
    profit: true,
  });
  // state end

  //Download Excel file
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const handleDownloadExcel = (apiData, fileName, headers) => {
    const applyCellStyle = (cell, style, ws, index, isBody) => {
      ws[cell].s = {
        font: {
          sz: style?.fontSize && style?.fontSize,
          color: { rgb: style?.fontColor || "FFFFFF" },
          bold: style.bold,
          italic: style.italic,
          underline: style.underline,
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: style.fillColor },
        },
        alignment: { horizontal: "center", vertical: "center" },
        border: {
          top: { style: "thin", color: { rgb: isBody ? "bfbfbf" : "FFFFFF" } },
          left: { style: "thin", color: { rgb: isBody ? "bfbfbf" : "FFFFFF" } },
          bottom: {
            style: "thin",
            color: { rgb: isBody ? "bfbfbf" : "FFFFFF" },
          },
          right: {
            style: "thin",
            color: { rgb: isBody ? "bfbfbf" : "FFFFFF" },
          },
        },
      };
    };

    const applyStylesToRow = (row, styles, ws, isBody) => {
      if (isBody) {
        row.forEach((row, i) => {
          row.forEach((cell, index) => {
            let fillColor =
              index === 2 && apiData[i]?.Trade === "Buy"
                ? "008000"
                : index === 2 && apiData[i]?.Trade === "Sell"
                ? "FF0000"
                : index === 2 && apiData[i]?.Trade === "Watch"
                ? "3a6dd6"
                : "FFFFFF";
            let fontColor =
              apiData[i]?.["Profit & Loss"]?.charAt(0) === "+"
                ? "008000"
                : apiData[i]?.["Profit & Loss"]?.charAt(0) === "-"
                ? "FF0000"
                : index === 2 &&
                  ["Buy", "Sell", "Watch"].includes(apiData[i]?.Trade)
                ? "FFFFFF"
                : "000000";
                
            applyCellStyle(
              cell,
              { ...styles[index], fillColor, fontColor },
              ws,
              i,
              isBody
            );
          });
        });
      } else {
        row.forEach((cell, index) => {
          applyCellStyle(cell, styles[index], ws, index);
        });
      }
    };

    const wsData = [];

    //Row Data
    const mainHeading = [`${chartView} Report Data`];
    const headersKeys = [];
    for (var key in headers) {
      headersKeys.push(key);
    }

    wsData.push(mainHeading);
    wsData.push(headersKeys);
    apiData.forEach((item) => {
      wsData.push(Object.values(item));
    });

    //Series Data

    const mainHeaderRow = ["A1"];

    const RowA = headersKeys.map((_, colIndex) => {
      return XLSX.utils.encode_cell({ r: 1, c: colIndex });
    });

    const RowB = apiData.map((_, index) => {
      return headersKeys.map((_, colIndex) => {
        return XLSX.utils.encode_cell({ r: index + 2, c: colIndex });
      });
    });

    const stylesMainHeader = Array.from({ length: 1 }, (_, index) => {
      return {
        fontSize: 15,
        fillColor: "003366",
        bold: true,
        italic: false,
        underline: false,
      };
    });
    const stylesRowA = Array.from(
      { length: headersKeys?.length },
      (_, index) => {
        return {
          fillColor: "004C99",
          bold: true,
          italic: false,
          underline: false,
        };
      }
    );

    const stylesRowB = Array.from(
      { length: headersKeys?.length },
      (_, index) => {
        return {
          fontColor: "000000",
          fillColor: "FFFFFF",
          bold: false,
          italic: false,
          underline: false,
        };
      }
    );

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    applyStylesToRow(mainHeaderRow, stylesMainHeader, ws, false);
    applyStylesToRow(RowA, stylesRowA, ws, false);
    applyStylesToRow(RowB, stylesRowB, ws, true);

    const mainHeadingRange = {
      s: { r: 0, c: 0 },
      e: { r: 0, c: headersKeys.length - 1 },
    };
    ws["!merges"] = [mainHeadingRange];

    //Set Cell Width

    let colWidth;
    if (value === 1) {
      colWidth = [
        { wch: 10 },
        { wch: 30 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
      ];
    } else {
      colWidth = [
        { wch: 10 },
        { wch: 30 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 20 },
      ];
    }
    ws["!cols"] = colWidth;

    let rowHeights = [
      {
        hpt: 40,
      },
      { hpt: 25 },
    ];

    apiData?.forEach((_, index) => {
      rowHeights?.push({ hpt: 20 });
    });

    ws["!rows"] = rowHeights;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blob, fileName + fileExtension);
  };

  const handleDownload = () => {
    const visibleColumns =
      value === 0
        ? Object.keys(performanceColumnVisibilityModel).filter(
            (key) => performanceColumnVisibilityModel[key]
          )
        : Object.keys(orderColumnVisibilityModel).filter(
            (key) => orderColumnVisibilityModel[key]
          );

    const customExcelData = pageData?.map((item, index) => {
      const rowData = {};
      visibleColumns.forEach((column) => {
        const columnHeader =
          value === 0 ? performanceColumnHeader : orderColumnHeader;
        const columnName = columnHeader[column];
        const itemValue = item[column];

        switch (column) {
          case "id":
            rowData["Sr."] = index + 1;
            break;
          case "entryDateTime":
            rowData["Entry Date & Time"] =
              value === 0
                ? Index.moment(itemValue).format("DD/MM/YYYY HH:mm:ss") || "-"
                : itemValue || "-";

            break;
          case "exitDateTime":
            rowData["Exit Date & Time"] =
              Index.moment(itemValue).format("DD/MM/YYYY HH:mm:ss") || "-";
            break;
          default:
            rowData[columnName] = itemValue || "-";
        }
      });
      return rowData;
    });

    if (customExcelData?.length) {
      handleDownloadExcel(
        customExcelData,
        value == 0
          ? `${location?.state?.row?.firstName} Performance Report`
          : `${location?.state?.row?.firstName} Order Report`,
        customExcelData[0]
      );
    }
  };

  // function start

  const getAllReports = async () => {
    try {
      const {reset, order, tags, timeFrame} = sorting[Constants.sortingPath[location?.pathname]]
      const response = await Index.DataService(userLoginToken).get(
        Index.Api.user.getAllReports,
        { params: { 
          tradeType: chartView, 
          reportType: value, 
          userId,
          sortDate: reset ? reset : '',
          sort: order ? order : '',
          tags: tags,
          timeFrameSort: timeFrame ? timeFrame : '',
        }
       }
      );
      if (response?.data?.data?.length) {
        setAllTagsData(response?.data?.data);
        setTempAllTagsData(response?.data?.data);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else {
        setAllTagsData([]);
        setTempAllTagsData([]);
        setPageData([]);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {
    getAllReports();
  }, [value, chartView, sorting[Constants.sortingPath[location?.pathname]]]);

  const handleChange = (event, newValue) => {
    setLoader(true);
    setValue(newValue);
    setPageValue(1);
    setFilters({});
  };
  // function end

  // table data filter start
  const performanceReportRow = useMemo(() => {
    return allTagsData
      ?.filter((item) => item?.status === "Inactive")
      .map((item, index) => {
        const TotalInvestment = +item.entryPrice;
        const profitLoss = (item?.exitPrice - item?.entryPrice)?.toFixed(2);

        const profitLossPercent = (
          (profitLoss / parseInt(item.entryPrice)) *
          100
        )?.toFixed(2);

        const formattedEntryTime = new Date(
          item?.createdAt
        )?.toLocaleTimeString("en-US", { hour12: false });
        const formattedExitTime = new Date(item?.updatedAt)?.toLocaleTimeString(
          "en-US",
          { hour12: false }
        );
        const formattedDate = new Date(item?.updatedAt)?.toLocaleDateString(
          "en-GB"
        );

        return {
          id: index + 1,
          script: item?.stock_name,
          tag: item?.tag,
          timeFrame: item?.timeFrame || "-",
          token: JSON.parse(item?.token),
          entry: item?.entryPrice || "-",
          entryTime: formattedEntryTime || "-",
          entryDateTime: item?.createdAt || "-",
          targetStatus: item?.targetAt?.completed,
          stopLossStatus: item?.topLossAt?.completed,
          hitStatus:
            (item?.topLossAt?.completed && "Stop Loss") ||
            (item?.targetAt?.completed && "Target") ||
            "-",
          exit: item?.exitPrice || "-",
          ExitTime: formattedExitTime || "-",
          exitDateTime: item?.updatedAt || "-",
          date: formattedDate || "-",
          profit:
            item?.topLossAt?.completed || item?.targetAt?.completed
              ? +item?.entryPrice > +item?.exitPrice
                ? `${profitLoss || 0} ( ${profitLossPercent} % )`
                : `+${profitLoss || 0} ( ${profitLossPercent} % )`
              : " -",
        };
      });
  }, [allTagsData]);

  const orderReportRow = useMemo(() => {
    return allTagsData
      ?.filter((item) => item?.status === "Active")
      .map((item, index) => ({
        id: index + 1,
        script: item?.stock_name,
        tag: item?.tag,
        timeFrame: item?.timeFrame || "-",
        token: JSON.parse(item?.token),
        CMP: item?.livePrice,
        entry: item?.entryPrice,
        StopLoss: item?.topLossAt?.price || "-",
        Target: item?.targetAt?.price || "-",
        entryDateTime: ` ${new Date(item?.createdAt)?.toLocaleDateString(
          "en-GB"
        )} \u00A0 ${new Date(item?.createdAt)?.toLocaleTimeString("en-US", {
          hour12: false,
        })}`,
        date: new Date(item?.createdAt)?.toLocaleDateString("en-GB") || "-",
      }));
  }, [allTagsData]);

    useEffect(() => {
      if (value === 0) {
        setPageData(performanceReportRow);
      } else {
        setPageData(orderReportRow);
      }
    }, [orderReportRow, performanceReportRow, allTagsData]);

  // pagination start
  const handlePageChange = (event, value) => {
    setPageValue(value);
  };

  useEffect(() => {
    const countData = Math.ceil(
      value === 0
        ? performanceReportRow?.length / rowsPerPage
        : orderReportRow?.length / rowsPerPage
    );
    setCount(countData);
    const indexOfLastRow = pageValue * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const modifiedRows =
      value === 0
        ? performanceReportRow?.slice(indexOfFirstRow, indexOfLastRow)
        : orderReportRow?.slice(indexOfFirstRow, indexOfLastRow);
        const rowData = value === 0 ? performanceReportRow : orderReportRow;
        // setPageData(rowData);

    // if (modifiedRows.length===0 && pageValue>1) {
    //   setPageValue(pageValue-1)
    // }
  }, [pageValue, value, allTagsData, rowsPerPage]);

  // pagination end
  const handleElementClick = (e, xAxis, yAxis) => {
    dispatch(
      manageModalParameter({ x: e.clientX - xAxis, y: e.clientY + yAxis })
    );
  };

  //Filter Report Data

  useEffect(() => {
    if (!tempAllTagsData) return;

    const filteredRows = tempAllTagsData.filter((ele) => {
      const entryDate = Index.moment(
        value === 0 ? ele?.updatedAt : ele?.createdAt
      ).format("DD-MM-YYYY");

      const fromDate =
        filters?.fromDate &&
        Index.moment(filters?.fromDate).format("DD-MM-YYYY");
      const toDate =
        filters?.toDate && Index.moment(filters?.toDate).format("DD-MM-YYYY");

      const filterConditions = [];

      // if (filters?.categories?.length) {
      //   filterConditions.push(
      //     filters?.categories?.includes(ele?.exchange_type)
      //   );
      // }
      if (filters?.market) {
        filterConditions.push(ele?.exchange_type === filters?.market);
      }
      if (filters?.script) {
        filterConditions.push(
          filters?.script === "All" ? ele : ele?.script?.toLowerCase() === filters?.script?.toLowerCase()
        );
      }
      if (!!filters?.tag?.length) {
        filterConditions.push(filters?.tag?.includes(ele?.tag));
      }
      if (filters?.stockName) {
        filterConditions.push(
          ele?.stock_name?.toLowerCase() === filters?.stockName?.toLowerCase()
        );
      }
      if (filters?.fromDate && filters?.toDate) {
        filterConditions.push(entryDate >= fromDate && entryDate <= toDate);
      }

      return filterConditions.every((condition) => condition);
    });

    setAllTagsData(filteredRows);
  }, [tempAllTagsData, filters, chartView]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  //Data table height
  let mainContentElement = document.querySelector(".main-content-sec");
  let tabSecElement = document.querySelector(".tab-wrapper");

  useEffect(() => {
    if (mainContentElement && tabSecElement) {
      const mainHeight = mainContentElement.clientHeight;
      const tabSecHeight = tabSecElement.clientHeight;
      setDataTableHeight(mainHeight - tabSecHeight - 30);
    }
  }, [mainContentElement, tabSecElement, twoSideArrowZoom]);

  return (
    <>
      <Index.Box className="dashboard-main performance-report-wrap">
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            <Index.Box className="tab-content-main report-tab-content-main">
              <Index.Box sx={{ width: "100%" }} className="tab-details-content">
                <Index.Box
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  className="tab-wrapper tab-border-content"
                >
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="tabs-content-top performance-report-top-btn"
                  >
                    <Index.Tab
                      label="Performance Report"
                      {...a11yProps(0)}
                      className="tabs-content-title report-tab-content-title"
                      disableRipple
                    />
                    <Index.Tab
                      label="Open Order Report"
                      {...a11yProps(1)}
                      className="tabs-content-title report-tab-content-title"
                      disableRipple
                    />
                  </Index.Tabs>
                  <Index.Box className="editRepot-download-sec">
                    {tempAllTagsData?.length ? (
                      <Index.PrimaryButton
                        className="primary-btn edit-download-btn"
                        btnLabel="Filter Report"
                        onClick={(e) => {
                          handleElementClick(e, 200, 50);
                          setreportModal(!reportModal);
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {pageData?.length ? (
                      <Index.PrimaryButton
                        className="primary-btn edit-download-btn green-bg"
                        btnLabel="Download"
                        onClick={handleDownload}
                      />
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="tab-body">
                  <TabPanel
                    value={value}
                    index={0}
                    className="report-tab-panel performance-tab"
                  >
                    <Index.Box className="">
                      <Index.Box className="table-sec report-tab-sec">
                        <Index.Box
                          sx={{ width: "100%", height: dataTableHeight }}
                          className="user-tagged-scripts-performance"
                        >
                          {/* {!loader ? (
                            pageData && pageData.length > 0 ? ( */}
                              <Index.DataGrid
                                ref={dataGridRef}
                                rows={loader ? [] : pageData}
                                columns={columns}
                                initialState={{
                                  pagination: {
                                    paginationModel: {
                                      pageSize: 10,
                                    },
                                  },
                                }}
                                pageSizeOptions={[1000]}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                // checkboxSelection
                                disableRowSelectionOnClick
                                columnVisibilityModel={
                                  performanceColumnVisibilityModel
                                }
                                onColumnVisibilityModelChange={(newModel) => {
                                  setPerformanceColumnVisibilityModel(newModel);
                                }}
                                components={{
                                  NoRowsOverlay: ()=> <Index.NoRowsOverlay loader={loader}/>, 
                                }}
                              />
                            {/* ) : (
                              <Index.Box
                                sx={{ textAlign: "center", padding: "20px" }}
                                className="text-data"
                              >
                                Performance report not found
                              </Index.Box>
                            )
                          ) : (
                            <Index.TableLoader />
                          )} */}
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Footer /> */}
                    </Index.Box>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    className="report-tab-panel"
                  >
                    <Index.Box className="">
                      <Index.Box className="table-sec report-tab-sec">
                        <Index.Box
                          sx={{ width: "100%", height: dataTableHeight }}
                          className="open-order-report"
                        >
                          {/* {!loader ? (
                            pageData && pageData.length > 0 ? ( */}
                              <Index.DataGrid
                                ref={dataGridRef}
                                rows={loader ? [] : pageData}
                                columns={OrderColumns}
                                initialState={{
                                  pagination: {
                                    paginationModel: {
                                      pageSize: 10,
                                    },
                                  },
                                }}
                                pageSizeOptions={[1000]}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                // checkboxSelection
                                disableRowSelectionOnClick
                                columnVisibilityModel={
                                  orderColumnVisibilityModel
                                }
                                onColumnVisibilityModelChange={(newModel) => {
                                  setOrderColumnVisibilityModel(newModel);
                                }}
                                components={{
                                  NoRowsOverlay: ()=> <Index.NoRowsOverlay loader={loader}/>, 
                                }}
                              />
                            {/* ) : (
                              <Index.Box
                                sx={{ textAlign: "center", padding: "20px" }}
                                className="text-data"
                              >
                                Open order report not found
                              </Index.Box>
                            )
                          ) : (
                            <Index.TableLoader />
                          )} */}
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Footer /> */}
                    </Index.Box>
                  </TabPanel>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {/* <Index.Box className="footer-sec">
          <Index.Box className="pagination-sec">
            <Index.Stack spacing={4}>
              <Index.Pagination
                // count={Math.ceil(wishListData?.length / 81)}
                count={count}
                page={pageValue}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box> */}
      </Index.Box>
      <Modal
        show={reportModal}
        setShow={setreportModal}
        title={"Report"}
        position={position}
        x={position?.x}
        y={position?.y}
        width={"393px"}
        html={
          <Index.ReportModal
            setFilters={setFilters}
            filters={filters}
            setShow={setreportModal}
            row={tempAllTagsData}
          />
        }
      />
    </>
  );
};

export default memo(UserTaggedScripts);
