import React, { memo, useState, useEffect, useCallback, useMemo, useRef } from "react";
import Index from "../../../Index";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import Modal from "../../../../component/modal/Modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  activeShortCut,
  manageModalParameter,
} from "../../../../redux/user/action";
import Constants from "../../../../component/common/constants";
import useRowPerPage from "../../../../component/hooks/useRowPerPage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const IndexManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = Index.useLocation();
  const {
    displaySettings,
    token: authToken,
    twoSideArrowZoom,
    position,
    sorting,
    theme,
  } = Index.useSelector((state) => state.UserReducer);
  
  const dataGridRef = useRef(null); 
  const [openAddIndex, setOpenAddIndex] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [indexList, setIndexList] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [DeleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const themeTitle = localStorage.getItem("default-theme");
  const [dataTableHeight, setDataTableHeight] = useState(0);

  const handleOpenDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDeleteId(null);
  };

  //Modal

  const handleAddIndexOpen = () => {
    setOpenAddIndex((prev) => !prev);
  };
  const handleAddIndexClose = () => {
    setOpenAddIndex((prev) => !prev);
  };

  const row = useMemo(() => {
    return (
      indexList?.length &&
      indexList?.map((item, index) => {
        return {
          id: index + 1,
          _id: item?._id,
          indexName: item?.indexName,
          category: item?.category,
          stockCount: item?.stockCount,
          stocks: item?.stocks,
          isActive: item?.isActive,
        };
      })
    );
  }, [indexList]);

  const [rowsPerPage] = useRowPerPage(dataGridRef, dataTableHeight, 52, row);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 100,
      hideable: false,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "indexName",
      headerName: "Index Name",
      width: 300,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stockCount",
      headerName: "Stock Count",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const id = params.row._id;
        const status = params.row.isActive;
        return (
          <Index.Box>
            <Index.Box className="d-flex">
              <Index.Switch
                className="switch-main"
                color="success"
                checked={status}
                inputProps={{
                  "aria-label": "controlled",
                }}
                onClick={() => activeDeactiveIndex(id, !status)}
              />
            </Index.Box>
          </Index.Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 250,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const id = params.row._id;
        const status = params.row.isActive;
        return (
          <Index.Box>
            <Index.Box className="d-flex">
              <button
                className="table-gray-btn"
                disableRipple
                onClick={(e) => {
                  handleElementClick();
                  setEditIndex(params.row);
                  handleAddIndexOpen();
                }}
              >
                <Index.EditIcon className="action-btn-img action-btn-icon" />
              </button>
              <button
                className="table-gray-btn"
                disableRipple
                onClick={() => handleOpenDelete(id)}
              >
                <img src={Index.Svg.deleteRed} className="action-btn-img" />
              </button>
              <button
                className="table-gray-btn"
                disableRipple
                onClick={() =>
                  navigate("/user/index-management/detail", {
                    state: { row: params.row },
                  })
                }
              >
                <img src={Index.Svg.eyeIcon} className="action-btn-img" />
              </button>
            </Index.Box>
          </Index.Box>
        );
      },
    },
  ];

  //Delete Index
  const activeDeactiveIndex = async (id, status) => {
    try {
      const response = await Index.DataService(authToken).post(
        Index.Api.admin.activeDeactiveIndex,
        { id, status }
      );
      // Index.toast.success(response.data.message);
      getAllIndex();
    } catch (error) {}
  };

  //Delete Index
  const removeIndex = async () => {
    try {
      const response = await Index.DataService(authToken).post(
        Index.Api.admin.deleteIndex,
        { id: DeleteId }
      );
      // Index.toast.success(response.data.message);
      getAllIndex();
    } catch (error) {}
    handleCloseDelete();
  };

  //Get all index
  const getAllIndex = async (values) => {
    try {
      const {reset, order} = sorting[Constants.sortingPath[location?.pathname]];
      const params = {
        sortDate: reset ? reset : '',
        sort: order ? order : '',
      }
      const response = await Index.DataService(authToken).get(
        Index.Api.admin.getAllIndex,
        {params}
      );
      setIndexList(response.data.data);
      setTimeout(()=>{
        setLoader(false);
      }, 1000);
    } catch (error) {
      setTimeout(()=>{
        setLoader(false);
      }, 1000);
    }
  };
  useEffect(() => {
    getAllIndex();
  }, [sorting[Constants.sortingPath[location?.pathname]]]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  //Data table height
  let mainContentElement = document.querySelector(".main-content-sec");
  let topButtonSecElement = document.querySelector(".index-management-flex");

  useEffect(() => {
    if (mainContentElement && topButtonSecElement) {
      const mainHeight = mainContentElement.clientHeight;
      const flexHeight = topButtonSecElement.clientHeight;
      setDataTableHeight(mainHeight - flexHeight - 27);
    }
  }, [mainContentElement, topButtonSecElement, twoSideArrowZoom]);

  const handleElementClick = () => {
    const x = (window.innerWidth - 615) / 2;
    const y = (window.innerHeight - 400) / 2;
    dispatch(manageModalParameter({ x, y }));
  };

  useEffect(() => {
    if (!openAddIndex) {
      setEditIndex(null);
    }
  }, [openAddIndex]);

  return (
    <>
      <Index.Box className="dashboard-wrapper">
        <Index.Header></Index.Header>
        <Index.Overlay></Index.Overlay>
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } ${twoSideArrowZoom && "hide-main-content-margin"}`}
        >
          <Index.Box className="index-management-flex">
            <Index.Box className="index-management-add-flex" >
              <Index.Box
                className="back-btn-main"
                onClick={() => navigate("/user/profile")}
              >
                <img
                  className="report-back-img"
                  alt="back"
                  src={Index.Svg.leftRoundArrow}
                />
              </Index.Box>
              <Index.PrimaryButton
                className="primary-btn index-btn add-index"
                btnLabel="Add Index"
                onClick={(e) => {
                  handleElementClick();
                  handleAddIndexOpen();
                }}
                />
              </Index.Box>
          </Index.Box>
          <Index.Box className="table-sec">
            <Index.Box
              sx={{ width: "100%", height: dataTableHeight }}
              className="index-management-table custom-index-table"
            >
              {/* {!loader ? (
                row && row.length > 0 ? ( */}
                  <Index.DataGrid
                    ref={dataGridRef}
                    rows={loader ? [] : row}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[1000]}
                    paginationModel={paginationModel}
                    // onPaginationModelChange={setPaginationModel}
                    onPaginationModelChange={({ page, pageSize }) => {
                      setPaginationModel({ page, pageSize });
                      // dispatch(manageListSettings({ page, pageSize }));
                    }}
                    disableRowSelectionOnClick
                    components={{
                      NoRowsOverlay: ()=> <Index.NoRowsOverlay loader={loader}/>, 
                    }}
                    localeText={{
                      MuiTablePagination: {
                        labelDisplayedRows: ({
                          from,
                          to,
                          count,
                          estimated,
                        }) => {
                          if (!estimated) {
                            return `${from} – ${to} of ${
                              count !== -1 ? count : `${to}`
                            }`;
                          }
                          return `${from} – ${to} of ${
                            count !== -1
                              ? count
                              : `${estimated > to ? estimated : to}`
                          }`;
                        },
                      },
                    }}
                  />
                {/* ) : (
                  <Index.Box
                    sx={{ textAlign: "center", padding: "20px" }}
                    className="text-data"
                  >
                    No record found
                  </Index.Box>
                )
              ) : (
                <Index.TableLoader />
              )} */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Modal
        show={openAddIndex}
        position={position}
        setShow={setOpenAddIndex}
        title={`${editIndex ? "Update" : "Add"} Index`}
        x={position?.x}
        y={position?.y}
        width={"615px"}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        html={
          <AddIndex
            handleAddIndexOpen={handleAddIndexOpen}
            getAllIndex={getAllIndex}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
          />
        }
        opacity={opacity}
        setOpacity={setOpacity}
      />

      <Index.Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="del-user-img-box">
            <img src={Index.Svg.deleteIcon} className="image-delete-user" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records?
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={removeIndex}
            >
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default IndexManagement;

const AddIndex = (props) => {
  const themeTitle = localStorage.getItem("default-theme");
  const { handleAddIndexOpen, getAllIndex, editIndex, setEditIndex } = props;
  const dispatch = Index.useDispatch();
  const { token, theme } = Index.useSelector((state) => state.UserReducer);
  const stockCategories = [
    { id: 1, name: "Equity", value: "NSE" },
    { id: 2, name: "F&O", value: "NFO" },
    { id: 3, name: "Commodity", value: "MCX" },
  ];
  let initialValues = {
    indexName: editIndex ? editIndex?.indexName : "",
    category: editIndex ? editIndex?.category : "",
    stocksCount: editIndex ? editIndex?.stockCount : "",
  };

  //Validation
  const addIndexValidationSchema = Yup.object().shape({
    indexName: Yup.string().required("Please enter index name"),
    category: Yup.string().required("Please select category"),
    stocksCount: Yup.string().required("Please enter stocks count"),
  });
  const handleSubmit = async (values) => {
    try {
      const urlencoded = new URLSearchParams();

      editIndex && urlencoded.append("id", editIndex?._id);
      urlencoded.append("indexName", values.indexName);
      urlencoded.append("category", values.category);
      urlencoded.append("stockCount", values.stocksCount);

      const response = await Index.DataService(token).post(
        Index.Api.admin.createIndex,
        urlencoded
      );
      // Index.toast.success(response.data.message);
      getAllIndex();
      setEditIndex(null);
    } catch (error) {}
    handleAddIndexOpen();
  };

  return (
    <>
      <Index.Box className="body-p sorting-body-wrapper">
        <Index.Box className="content-wrapper">
          <Formik
            validationSchema={addIndexValidationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              setFieldValue,
            }) => (
              <Form>
                <Index.Box className="name-wrap name-warp-add-index">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item sm={6} xs={12}>
                      <Index.Box className="cus-input-grp add-index-input-main">
                        <Index.Typography
                          className="cus-lable light-font"
                          variant="p"
                          component="p"
                        >
                          Index Name
                        </Index.Typography>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="indexName"
                            className="form-control"
                            value={values?.indexName}
                            placeholder="Enter Index Name"
                            onChange={handleChange}
                            onBlur={(e) => {
                              handleBlur(e);
                              dispatch(activeShortCut(true));
                            }}
                            onFocus={(e) => {
                              dispatch(activeShortCut(false));
                            }}
                          />
                          <Index.FormHelperText error className="error-text">
                            {errors.indexName && touched.indexName
                              ? errors.indexName
                              : null}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item sm={6} xs={12}>
                      <Index.Box className="cus-input-grp exit-stock-modal-box index-select">
                        <Index.Typography
                          className="cus-lable light-font"
                          variant="p"
                          component="p"
                        >
                          Category
                        </Index.Typography>
                        <Index.Box className="form-group">
                          <Index.FormControl className="form-control-user drop-form-control">
                            <Index.Select
                              fullWidth
                              name="category"
                              className={`form-control ${
                                !values?.category || theme === "Light"
                                  ? "select-form-control"
                                  : ""
                              }`}
                              displayEmpty
                              value={values?.category}
                              placeholder="User Type"
                              // onChange={handleChange}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {values?.category === "" ||
                              values?.category === undefined ? (
                                <Index.MenuItem
                                  disabled
                                  value=""
                                  className="form-control"
                                >
                                  Category
                                </Index.MenuItem>
                              ) : null}
                              {stockCategories?.map((ele) => {
                                return (
                                  <Index.MenuItem
                                    key={ele?.id}
                                    value={ele?.value}
                                    // name="admin"
                                    className="drop-menuitem game-item"
                                  >
                                    {ele?.name}
                                  </Index.MenuItem>
                                );
                              })}
                            </Index.Select>
                          </Index.FormControl>
                          <Index.FormHelperText error className="error-text">
                            {errors.category && touched.category
                              ? errors.category
                              : null}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item sm={6} xs={12}>
                      <Index.Box className="cus-input-grp add-index-input-main">
                        <Index.Typography
                          className="cus-lable light-font"
                          variant="p"
                          component="p"
                        >
                          Stocks Count
                        </Index.Typography>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="stocksCount"
                            className="form-control"
                            value={values?.stocksCount}
                            placeholder="Enter Stocks Count"
                            onChange={(e) => {
                              let value = e?.target?.value?.replace(/\D/g, "");
                              setFieldValue("stocksCount", value);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              dispatch(activeShortCut(true));
                            }}
                            onFocus={(e) => {
                              dispatch(activeShortCut(false));
                            }}
                          />
                          <Index.FormHelperText error className="error-text">
                            {errors.stocksCount && touched.stocksCount
                              ? errors.stocksCount
                              : null}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                  <Index.Box className="index-btn-main">
                    <Index.PrimaryButton
                      className="primary-btn save-changes-btn"
                      btnLabel={editIndex ? "Update" : "Add"}
                      type="submit"
                    />
                  </Index.Box>
                </Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};
