import type from "./type";
import Index from "../../container/Index";
import Constants from "../../component/common/constants";

const initialState = {
  isUserLogin: false,
  UserDetails: {},
  graphType: Constants.defaultGraphType,
  GraphStyle: Constants.defaultGraphStyle,
  userLoginDetail: {},
  singleUserDetails: [],
  userProfileDetail: [],
  profileDetailLoading: false,
  token: "",
  currentTag: "",
  angelListData: [],
  wishListData: [],
  wishListDataTemp: [],
  chartDuration: {
    Intraday: { interval: "ONE_MINUTE" },
    Positional: { timeFrame: 5, interval: "FIVE_MINUTE" },
  },
  // timeFrame: 5,
  timeFrame: {
    day: 5,
    interval: "FIVE_MINUTE",
  },
  showHeaderSearch: false,
  openDefaultSettingModal: false,
  chartView: "Positional",
  liveData: [],
  gridView: { column: 1, row: 1 },
  pageSetting: {},
  listSetting: { page: 0 },
  getAllShortcuts: [],
  isActiveShortCut: true,
  currentPageRedux: 1,
  isSortedWishlist: false,
  gridSpacing: 1,
  gridBorder: 5,
  gridColor: "#ffffff",
  previousGridColor: "#ffffff",
  selectedScript: { market: "NSE", script: "NIFTY 50" },
  otherSettings: Constants.defaultOtherSettings,
  displaySettings: {
    ScriptDetails: "on",
    cmp: "on",
    change: "Rs",
    TimeScale: "on",
    PriceScale: "on",
    OrderPrice: "on",
    CursorTime: "on",
    OHLCValues: "on",
    Alerts: "on",
    StockName: "on",
    StockSymbol: "on",
    PriceIndicator: "on",
    UtilityBar: "right",
    StopLossTargetPrice: "on",
    LastUpdatedTime: "on",
    DaySplitter: "off",
    AutoSquare: "on",
  },
  chartDrawingData: [],
  twoSideArrowZoom: false,
  activeChartHover: null,
  activeTrackingChartHover: [],
  downloadChartToken: [],
  miniChartSize: { width: 200, height: 100 },
  isDrawingButtonActive: false,
  isDrawingToolOpen: true,
  isModalDrawingButtonActive: false,
  isModalDrawingToolOpen: false,
  drawingToolsList: [],
  toolOrientation: false,
  selectedChart: [],
  isFlip: false,
  rowsPerPage: 10,
  chartDefaultSettings: {
    chartView: "Intraday",
    Intraday: {
      interval: "ONE_MINUTE",
      chartType: 0,
    },
    Positional: {
      timeFrame: 5,
      interval: "FIFTEEN_MINUTE",
      chartType: 0,
    },
  },
  categoryFilter: [],
  chartViewChanged: false,
  sortingTags: [],
  customScripts: [],
  isExitAllModalOpen: false,
  taggedStocksCount: {},
  showSideBar: false,
  position: { x: 0, y: 0 },
  isEraseDrawing: false,
  sorting: Constants.defaultSorting,
  trackingWindowStocks: [],
  drawingStockToken: null,
  notificationCount: 0,
  theme: 'Light',
  autoSquareTime: Constants.defaultAutoSquareTime,
  usersSettings: null,
};
const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.fetchUserLoginSuccess:
      return {
        ...state,
        UserDetails: action.payload,
        isUserLogin: true,
      };
    case type.graphType:
      return {
        ...state,
        graphType: action.payload,
      };
    case type.graphStyle:
      return {
        ...state,
        GraphStyle: action.payload,
      };
    case type.USER_LOGIN_SUCCESS:
      return {
        ...state,
        userLoginDetail: action.payload.data,
        token: action.payload.data.token,
        isUserLogin: true,
      };
    case type.angelListData:
      return {
        ...state,
        angelListData: action.payload,
      };
    case type.wishListData:
      const sortedData = action?.payload?.sort((a, b) =>
        a?.name.localeCompare(b?.name)
      );
      return {
        ...state,
        wishListData: sortedData,
        wishListDataTemp: sortedData,
      };
    case type.wishListDataRemove:
      const data = state.wishListData.filter(
        (e) => +e.token !== +action.payload
      );
      return {
        ...state,
        wishListData: data,
        wishListDataTemp: data,
      };
    case type.wishListDataView:
      let arr = [...state.wishListData];
      let index = arr.findIndex((e) => +e.token === +action.payload.token);
      arr[index]["type"] = action.payload.type;
      return {
        ...state,
        wishListData: arr,
        wishListDataTemp: arr,
      };
    case type.setChartDuration:
      return {
        ...state,
        chartDuration: action.payload,
      };
    case type.timeFrame:
      return {
        ...state,
        timeFrame: action.payload,
      };
    case type.chartView:
      return {
        ...state,
        chartView: action.payload,
      };
    case type.liveData:
      return {
        ...state,
        liveData: action.payload,
      };
    case type.USERLOGOUT:
      return initialState;

    case type.EDIT_PROFILE:
      if (action.payload.status === 200) {
        // Index.toast.success(action.payload.message);
      }
      return {
        ...state,
        userLoginDetail: action.payload.data,
      };
    case type.headerSearchBar:
      return {
        ...state,
        showHeaderSearch: !state.showHeaderSearch,
      };
    case type.defaultSettingModal:
      return {
        ...state,
        openDefaultSettingModal: action.payload,
      };
    // case type.defaultSettingModal:
    //   return {
    //     ...state,
    //     openDefaultSettingModal: action.payload,
    //   };
    case type.gridView:
      return {
        ...state,
        gridView: action.payload,
        currentPageRedux: 1,
      };
    case type.pageSetting:
      return {
        ...state,
        pageSetting: action.payload,
        currentPageRedux: action.payload.page,
      };
    case type.CURRENT_PAGE:
      return {
        ...state,
        currentPageRedux: action.payload,
      };
    case type.activeShortCut:
      return {
        ...state,
        isActiveShortCut: action.payload,
      };
    case type.currentPageMinus:
      return {
        ...state,
        currentPageRedux:
          action.payload ||
          (state.currentPageRedux > 1 ? state.currentPageRedux - 1 : 1),
      };
    case type.currentPagePlus:
      return {
        ...state,
        currentPageRedux:
          state.pageSetting.count > state.currentPageRedux
            ? state.currentPageRedux + 1
            : state.currentPageRedux,
      };

    case type.sortingWishList:
      const d =
        action.payload == "reset"
          ? state.wishListDataTemp?.sort((a, b) => {
              const aName = a?.name || a?.stock_name;
              const bName = b?.name || b?.stock_name;
              return aName.localeCompare(bName);
            })
          : action.payload
          ? state.wishListData?.sort((a, b) => {
              const aName = a?.name || a?.stock_name;
              const bName = b?.name || b?.stock_name;
              return bName.localeCompare(aName);
            })
          : state.wishListData?.sort((a, b) => {
              const aName = a?.name || a?.stock_name;
              const bName = b?.name || b?.stock_name;
              return aName.localeCompare(bName);
            });
      const tempSort =
        action.payload == "reset"
          ? state.wishListDataTemp?.sort((a, b) => {
              const aName = a?.name || a?.stock_name;
              const bName = b?.name || b?.stock_name;
              return aName.localeCompare(bName);
            })
          : action.payload
          ? state.wishListDataTemp?.sort((a, b) => {
              const aName = a?.name || a?.stock_name;
              const bName = b?.name || b?.stock_name;
              return bName.localeCompare(aName);
            })
          : state.wishListDataTemp?.sort((a, b) => {
              const aName = a?.name || a?.stock_name;
              const bName = b?.name || b?.stock_name;
              return aName.localeCompare(bName);
            });
      return {
        ...state,
        wishListData: d,
        wishListDataTemp: tempSort,
        isSortedWishlist: action.payload == "reset" ? false : action.payload,
      };

    case type.GETALLSHORTCUTS:
      return {
        ...state,
        getAllShortcuts: action.payload,
      };

    case type.MANAGE_CURRENT_TAG:
      return {
        ...state,
        currentTag: action.payload.tag,
        wishListData:
          action.payload.tag === ""
            ? state.wishListDataTemp
            : state.wishListDataTemp.filter(
                (e) =>
                  action?.payload?.filterTag?.filter(
                    (el) => +el.token === +e.token
                  ).length
              ),
        wishListData: action?.payload?.tag?.length
          ? action?.payload?.filterTag
          : [...state.wishListDataTemp],
      };

    case type.MANAGE_GRID_BORDER:
      return {
        ...state,
        gridBorder: action.payload,
      };

    case type.MANAGE_GRID_SPACING:
      return {
        ...state,
        gridSpacing: action.payload,
      };

    case type.MANAGE_GRID_COLOR:
      const value = action?.payload?.type ? action?.payload?.color : action?.payload
      const currentState = action?.payload?.type ? 'previousGridColor' : 'gridColor';
      return {
        ...state,
        [currentState]: value,
      };

    case type.SELECTED_SCRIPT:
      return {
        ...state,
        selectedScript: action.payload,
      };

    case type.OTHER_SETTING:
      return {
        ...state,
        otherSettings: action.payload,
      };

    case type.DISPLAY_SETTINGS:
      return {
        ...state,
        displaySettings: action.payload,
      };

    case type.DRAW_CHART:
      return {
        ...state,
        chartDrawingData: action.payload,
      };

    case type.ACTIVE_CHART_HOVER:
      return {
        ...state,
        activeChartHover: action.payload,
      };

    case type.TRACKING_ACTIVE_CHART_HOVER:
      return {
        ...state,
        activeTrackingChartHover: action.payload,
      };

    case type.DOWNLOAD_CHART_TOKEN:
      return {
        ...state,
        downloadChartToken: action.payload,
      };

    case type.MINI_CHART_SIZE:
      return {
        ...state,
        miniChartSize: action.payload,
      };

    case type.SHOW_HIDE_DRAWING_BUTTON:
      const typeB = action?.payload?.type;
      const toolState = typeB
        ? "isModalDrawingButtonActive"
        : "isDrawingButtonActive";
        
      return {
        ...state,
        [toolState]: !state[toolState],
      };

    case type.DRAWING_TOOL_ACTIVE_DEACTIVE:
      const typeA = action?.payload?.type;
      const toolStateA = typeA ? "isModalDrawingToolOpen" : "isDrawingToolOpen";
      return {
        ...state,
        [toolStateA]: action.payload?.status
          ? action.payload.status
          : !state[toolStateA],
      };

    case type.DRAWING_TOOLS_LIST:
      return {
        ...state,
        drawingToolsList: action.payload,
      };

    case type.TOOL_ORIENTATION:
      return {
        ...state,
        toolOrientation: action.payload,
      };

    case type.CHART_INSTANCE:
      const resetInstance = action?.payload == "reset";
      const isChartExist = !resetInstance
        ? state.selectedChart?.some(
            (item) => item?.token == action?.payload?.token
          )
        : "";
      return {
        ...state,
        selectedChart: resetInstance
          ? []
          : isChartExist
          ? state.selectedChart?.filter(
              (item) => item?.token !== action?.payload?.token
            )
          : [...state.selectedChart, action.payload],
      };

    case type.FLIP_STATE:
      return {
        ...state,
        isFlip: !state.isFlip,
      };

    case type.ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };

    case type.CATEGORY_FILTER:
      return {
        ...state,
        categoryFilter: action.payload,
      };

    case type.CHART_VIEW_STATE:
      return {
        ...state,
        chartViewChanged: !state.chartViewChanged,
      };

    case type.DEFAULT_SETTINGS:
      return {
        ...state,
        chartDefaultSettings: action.payload,
      };

    case type.CUSTOM_SCRIPTS:
      return {
        ...state,
        customScripts: action.payload,
      };

    case type.SORTING_TAGS:
      const tag = action.payload;
      return {
        ...state,
        sortingTags: tag
          ? state.sortingTags?.includes(tag)
            ? state.sortingTags?.filter((tagName, i) => tagName !== tag)
            : [...state.sortingTags, tag]
          : [],
      };

    case type.EXIT_ALL_MODAL:
      return {
        ...state,
        isExitAllModalOpen: !state.isExitAllModalOpen,
      };

    case type.LIST_SETTINGS:
      return {
        ...state,
        listSetting: action.payload,
      };

    case type.TAGGED_STOCKS_COUNT:
      return {
        ...state,
        taggedStocksCount: action.payload,
      };
    case type.SIDEBAR_VISIBILITY:
      return {
        ...state,
        showSideBar: action.payload,
      };
    case type.MODAL_PARAMETERS:
      return {
        ...state,
        position: action.payload,
      };

    case type.TWO_SIDE_ARROW_ZOOM:
      return {
        ...state,
        twoSideArrowZoom: action.payload,
      };

    case type.ERASE_DRAWING:
      return {
        ...state,
        isEraseDrawing: action.payload,
      };

    case type.USERS_DISPLAY_SETTING:
      return {
        ...state,
        displaySettings: { ...state.displaySettings, ...action.payload },
      };

    case type.SORTING:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          [action.payload.pathName]: {
            ...state.sorting[action.payload.pathName],
            ...action.payload.data,
          },
        },
      };

    case type.TRACKING_WINDOW_STOCKS:
      return {
        ...state,
        trackingWindowStocks: action.payload,
      };

    case type.DRAWING_CHART_TOKEN:
      return {
        ...state,
        drawingStockToken: action.payload,
      };

    case type.NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };

    case type.DEFAULT_THEME:
      return {
        ...state,
        theme: action.payload,
      };

    case type.MANAGE_SETTINGS:
      return {
        ...state,
        usersSettings: action.payload,
        ...action.payload,
      };

    case type.MANAGE_AUTO_SQUARE_TIME:
      return {
        ...state,
        autoSquareTime: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
